/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import pjson from '../../../package.json';
import styles from './index.module.scss';

import {
  getStoreOfUser,
} from '../../services/stores';

const AdminLayout = ({
  title,
  back,
  user,
  redirectTo,
  requiredPermissions,
  component: Component,
  ...props
}) => {
  const history = useHistory();
  const [store, setStore] = useState(null);
  useEffect(async () => {
    const rsp = await getStoreOfUser();
    if (rsp.status) {
      setStore(rsp.store);
    }
  }, []);
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (!user || !user.id) {
      history.push('/login');
    }
    return null;
  }, [user]);
  if (!user) {
    return null;
  }
  if (!Component) {
    return null;
  }
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.head}>
              <div className={styles.header}>
                <div className={styles.inner}>
                  <div className={styles.logo}>
                    <Link to="/dashboard">
                      <img src="https://assets.qoopit.io/logo-qoopit-morado-claro.svg" alt="Qoopit" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles.menu}>
                <div className={styles.inner}>
                  <div className={styles.options}>
                    <div className={`${styles.option} ${props.path === '/dashboard' ? styles.selected : null}`}><Link to="/dashboard">Dashboard</Link></div>
                    <div className={`${styles.option} ${props.path === '/tienda' ? styles.selected : null}`}><Link to="/tienda">Información de la tienda</Link></div>
                    {store && store.is_pro
                      && <div className={`${styles.option} ${props.path === '/paginas' ? styles.selected : null}`}><Link to="/paginas">Páginas</Link></div>}
                    <div className={`${styles.option} ${props.path === '/catalogo' ? styles.selected : null}`}><Link to="/catalogo">Catálogo</Link></div>
                    <div className={`${styles.option} ${props.path === '/pedidos' ? styles.selected : null}`}><Link to="/pedidos">Pedidos</Link></div>
                    <div className={`${styles.option} ${props.path === '/suscriptores' ? styles.selected : null}`}><Link to="/suscriptores">Suscriptores</Link></div>
                    <div style={{ float: 'right' }}>
                      <div className={`${styles.option} ${props.path === '/logout' ? styles.selected : null}`}><Link to="/logout">Salir</Link></div>
                    </div>
                    {store
                      && <div className={`${styles.option}`}><a target="_blank" rel="noreferrer" href={`http://${store.slug}.qoopit.site`}><strong>Ver la tienda</strong></a></div>}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.inner}>
                <Component {...matchProps} user={user} store={store} />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.inner}>
                <div className={styles.version}>
                  {pjson.version}
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    />
  );
};

AdminLayout.defaultProps = {
  title: '',
  back: false,
  user: {},
  redirectTo: null,
  requiredPermissions: null,
};

AdminLayout.propTypes = {
  title: PropTypes.string,
  back: PropTypes.bool,
  user: PropTypes.object,
  redirectTo: PropTypes.string,
  path: PropTypes.any.isRequired,
  requiredPermissions: PropTypes.object,
  component: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  title: state.layout && state.layout.get('title'),
  back: state.layout && state.layout.get('back'),
  user: state && state.auth && state.auth.get('user') && state.auth.get('user').toJS(),
});

/*
const mapDispatchToProps = (dispatch) => ({

});
*/

export default connect(
  mapStateToProps,
  null, // mapDispatchToProps
)(AdminLayout);
