import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AutoComplete, Form } from 'antd';
import {
  getStoreOfUser,
} from '../../../../services/stores';
import styles from './index.module.scss';

const SectionBlock = ({
  block: propBlock,
  onChange,
}) => {
  const [block, setBlock] = useState(propBlock);
  const [sections, setSections] = useState(null);
  useEffect(async () => {
    const rsp = await getStoreOfUser();
    setSections(rsp.store.sections);
  }, []);
  useEffect(() => {
    if (!propBlock) {
      return;
    }
    setBlock(block);
  }, [propBlock]);

  const updateDataField = (field, value) => {
    const newBlock = {
      ...block,
      data: {
        ...block.data,
        [field]: value,
      },
    };
    console.log('new', newBlock);
    setBlock(newBlock);
    onChange(newBlock);
  };
  return (
    <div className={styles.block}>
      <Form
        name={`data-${block.id}`}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={block.data}
        autoComplete="off"
      >
        <Form.Item
          label="Sección a mostrar"
          {...{
            labelCol: { span: 4 },
          }}
          help="Elige una existente para mostrar los productos en la página"
          rules={[{ required: true, message: 'La sección es requerida' }]}
        >
          <AutoComplete
            options={sections && sections.map((x) => ({ value: x.name }))}
            filterOption={(inputValue, option) => (
              option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            )}
            defaultValue={block.data.section}
            onChange={(value) => updateDataField('section', value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
SectionBlock.defaultProps = {
  onChange: () => { },
};

SectionBlock.propTypes = {
  block: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default SectionBlock;
