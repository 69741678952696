export default (client, defaultParams) => ({
  getAll: () => (
    client('/landings', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (slug) => (
    client(`/landing/${slug}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  updateFields: (landingId, payload) => (
    client(`/landing/${landingId}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  updateBlock: (block) => (
    client(`/block/${block.id}`, {
      method: 'PUT',
      data: block,
      params: {
        ...defaultParams,
      },
    })
  ),
  setBlocksOrder: (landingId, ids) => (
    client(`/landing/${landingId}/blocks_order/${ids.join(',')}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  createLanding: (landing) => (
    client('/landing', {
      method: 'POST',
      data: landing,
      params: {
        ...defaultParams,
      },
    })
  ),
  addBlock: (block) => (
    client('/block', {
      method: 'POST',
      data: block,
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
