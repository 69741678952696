/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

import { CSVLink } from 'react-csv';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileCsv } from '@fortawesome/free-solid-svg-icons';

import {
  Input, Button, Table, Space,
} from 'antd';
import Highlighter from 'react-highlight-words';
import {
  FilterOutlined,
} from '@ant-design/icons';

import Loading from '../../components/Loading';

import {
  getAll,
} from '../../services/subscribers';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';

const SubscribersModule = () => {
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState(false);

  const [searchedColumn, setSearchedColumn] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const searchInput = useRef(null);

  const loadSubscribers = async (showLoading = true) => {
    if (showLoading) { setLoading(true); }

    const rsp = await getAll();
    if (rsp) {
      setSubscribers(rsp.subscribers);
    }
    if (showLoading) { setLoading(false); }
  };

  useEffect(() => {
    loadSubscribers();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar..."
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const headers = [
    'Email', 'Nombre', 'Fecha',
  ];
  const csvData = useMemo(() => {
    const data = subscribers
      ? subscribers.map((s) => ([
        s.email, s.name, moment.utc(s.created).local().format('YYYY-MM-DD HH:mm:ss'),
      ]))
      : [];
    return data;
  }, [subscribers]);

  const columns = [
    {
      title: 'Email',
      align: 'left',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => (a.email < b.email ? 1 : -1),
      ...getColumnSearchProps('hash'),
      render: (email, r) => email,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      align: 'left',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name < b.name ? 1 : -1),
      render: (name, r) => name,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      align: 'left',
      sorter: (a, b) => (a.created_at < b.created_at ? 1 : -1),
      // eslint-disable-next-line camelcase
      render: (created_at) => moment.utc(created_at).local().format('DD/MM HH:mm'),
    },
  ];

  return (
    <>
      <div className={stylesLayout.page}>
        <div className={stylesLayout.title}>
          Suscriptores
        </div>
        <div className={stylesLayout.subtitle}>
          Aquí puedes ver y descargar todos los suscriptores de tu tienda
        </div>
        {loading && <Loading />}
        {!loading
        && (
        <div className={styles.list}>
          <Button type="secondary">
            <CSVLink
              filename="suscriptores.csv"
              data={csvData}
              style={{ textDecoration: 'none' }}
              headers={headers}
            >
              <FontAwesomeIcon icon={faDownload} />
              {' '}
              Descargar CSV
            </CSVLink>
          </Button>
          <br />
          <br />
          <Table columns={columns} dataSource={subscribers} pagination={{ pageSize: 250, hideOnSinglePage: true, position: ['bottomCenter'] }} />
        </div>
        )}
      </div>
    </>
  );
};

export default SubscribersModule;
