/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Input, Button, Alert,
} from 'antd';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';

import {
  changePassword,
} from './logic';

import { setUser as setUserAction } from '../Auth/actions';

const ChangePasswordModule = ({
  setUser,
  user,
}) => {
  const { token } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    if (user && user.id) {
      history.push('/dashboard');
    }
    return null;
  }, [user]);

  const onFinish = async (values) => {
    setLoading(true);
    setError(null);
    const rsp = await changePassword({
      token,
      ...values,
    });
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setUser(rsp.data);
      history.push('/dashboard');
    }
    setLoading(false);
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.cnt}>
      <div className={styles.login}>
        <div className={`${styles.form} ${stylesForm.form}`}>
          <div className={styles.logo}>
            <img src="https://assets.qoopit.io/logo-qoopit-morado-claro.svg" alt="" />
          </div>
          {error
          && (
            <div className={stylesForm.error}>
              <Alert message={error} type="error" />
            </div>
          )}
          <div className={stylesForm.fields}>
            <Form
              name="basic"
              form={form}
              initialValues={{ email: '', password: '' }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
            >

              <Form.Item
                label="Password"
                name="password"
                help="Debe tener al menos 6 caracteres"
                rules={[{ required: true, message: 'El password es requerido' }, { min: 6, message: 'El password debe tener al menos 6 caracteres' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Cambiar contraseña
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangePasswordModule.defaultProps = {
  user: null,
};

ChangePasswordModule.propTypes = {
  setUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserAction(user)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ChangePasswordModule);
