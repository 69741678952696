/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'slugify';
import {
  Form, Input, Button, Alert,
} from 'antd';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';

import {
  createStore,
} from '../../services/stores';

import { setUser as setUserAction } from '../Auth/actions';

const CreateStoreModule = ({
  setUser,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState('');
  const [error, setError] = useState();
  const onChange = () => {
    let newSlug = slugify(form.getFieldValue('name').toLowerCase() || '');
    if (newSlug && newSlug.replaceAll) {
      newSlug = newSlug.replaceAll('.', '-');
    } else {
      newSlug = '';
    }
    setSlug(newSlug);
  };
  const onFinish = async (values) => {
    setLoading(true);
    setError(null);
    const rsp = await createStore({
      ...values,
      slug,
    });
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setUser(rsp.data.user);
      history.push('/dashboard');
    }
    setLoading(false);
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.cnt}>
      <div className={styles.message}>
        <div className={styles.logo}>
          <img src="https://assets.qoopit.io/logo-qoopit-blanco.svg" alt="" />
        </div>
        <div className={styles.big}>
          Crear una tienda online con Qoopit es sencillo y solo te llevará unos minutos.
        </div>
        <div className={styles.small}>
          Agrega tu toque personal y comienza
          {' '}
          a vender tus productos o servicios ahora mismo

        </div>
      </div>
      <div className={styles.sidebar}>
        <div className={`${styles.form} ${stylesForm.form}`}>
          <div className={styles.logo}>
            <img src="https://assets.qoopit.io/logo-qoopit-morado-claro.svg" alt="" />
          </div>

          <div className={stylesForm.title}>
            ¡Crea tu tienda gratis!
          </div>
          <div className={stylesForm.subtitle}>
            En dos simples pasos tendrás tu tienda lista. No necesitas tarjeta de crédito.
          </div>
          {error
            && (
              <div className={stylesForm.error}>
                <Alert message={error} type="error" />
              </div>
            )}
          <div className={stylesForm.fields}>
            <Form
              name="basic"
              form={form}
              initialValues={{ email: '', password: '', name: '' }}
              layout="vertical"
              onFinish={onFinish}
              onChange={onChange}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Form.Item
                label="Email"
                name="email"
                help="Este email no se mostrará a los clientes"
                rules={[{ required: true, message: 'El email es requerido' }]}
              >
                <Input placeholder="Tu email" type="email" />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                help="Debe tener al menos 6 caracteres"
                rules={[{ required: true, message: 'La contraseña es requerido' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Nombre de tu tienda"
                name="name"
                rules={[{ required: true, message: 'El Nombre de tu tienda es requerido' }]}
                help={`Tu tienda estará en https://${slug || '(nombre de la tienda)'}.qoopit.site`}
              >
                <Input />
              </Form.Item>
              <br />
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  ¡Crear tienda!
                </Button>
                <br />
                <br />
                <a onClick={() => history.push('/dashboard')}>Si ya tienes tienda entra aquí</a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateStoreModule.defaultProps = {
};

CreateStoreModule.propTypes = {
  setUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state && state.auth && state.auth.get('user') && state.auth.get('user'),
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserAction(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateStoreModule);
