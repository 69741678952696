import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Input } from 'antd';
import styles from './index.module.scss';

const SocialNetworks = ({
  block: propBlock,
  onChange,
}) => {
  const [block, setBlock] = useState(propBlock);
  useEffect(() => {
    if (!propBlock) {
      return;
    }
    setBlock(block);
  }, [propBlock]);

  const updateDataField = (field, value) => {
    const newBlock = {
      ...block,
      data: {
        ...block.data,
        [field]: value,
      },
    };
    setBlock(newBlock);
    onChange(newBlock);
  };
  return (
    <div className={styles.block}>
      <Form
        name={`data-${block.id}`}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={block.data}
        autoComplete="off"
      >
        <Form.Item
          label="Instagram"
        >
          <Input
            addonBefore="@"
            value={block.data.instagram}
            onChange={
              (e) => {
                updateDataField('instagram', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="Facebook"
        >
          <Input
            addonBefore="facebook.com/"
            value={block.data.facebook}
            placeholder=""
            onChange={
              (e) => {
                updateDataField('facebook', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="Twitter"
        >
          <Input
            addonBefore="@"
            value={block.data.twitter}
            placeholder=""
            onChange={
              (e) => {
                updateDataField('twitter', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="TikTok"
        >
          <Input
            addonBefore="@"
            value={block.data.tiktok}
            placeholder=""
            onChange={
              (e) => {
                updateDataField('tiktok', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="LinkedIn"
        >
          <Input
            addonBefore="https://www.linkedin.com/company/"
            value={block.data.linkedin}
            placeholder=""
            onChange={
              (e) => {
                updateDataField('linkedin', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="Número de WhatsApp"
          help="+56 9 xxxx xxxx. Este número será donde te contacten los clientes."
        >
          <Input
            value={block.data.whatsapp}
            placeholder=""
            onChange={
              (e) => {
                updateDataField('whatsapp', e.target.value);
              }
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
};
SocialNetworks.defaultProps = {
  onChange: () => { },
};

SocialNetworks.propTypes = {
  block: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default SocialNetworks;
