import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Input } from 'antd';
import styles from './index.module.scss';

const ButtonBlock = ({
  block: propBlock,
  onChange,
}) => {
  const [block, setBlock] = useState(propBlock);
  useEffect(() => {
    if (!propBlock) {
      return;
    }
    setBlock(block);
  }, [propBlock]);

  const updateDataField = (field, value) => {
    const newBlock = {
      ...block,
      data: {
        ...block.data,
        [field]: value,
      },
    };
    setBlock(newBlock);
    onChange(newBlock);
  };
  return (
    <div className={styles.block}>
      <Form
        name={`data-${block.id}`}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={block.data}
        autoComplete="off"
      >
        <Form.Item
          label="Título"
        >
          <Input
            value={block.data.label}
            placeholder="Título"
            onChange={
              (e) => {
                updateDataField('label', e.target.value);
              }
            }
          />
        </Form.Item>
        <Form.Item
          label="Enlace"
        >

          <Input
            value={block.data.url}
            placeholder="https://"
            onChange={
              (e) => {
                updateDataField('url', e.target.value);
              }
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
};
ButtonBlock.defaultProps = {
  onChange: () => { },
};

ButtonBlock.propTypes = {
  block: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default ButtonBlock;
