import apiClient from '../../api';

export const login = async (payload) => {
  try {
    const { data } = await apiClient.users.login(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return null;
};

export const forgetPassword = async (payload) => {
  try {
    const { data } = await apiClient.users.forgetPassword(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return null;
};

export const changePassword = async (payload) => {
  try {
    const { data } = await apiClient.users.changePassword(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return null;
};

export default {
  login,
  forgetPassword,
  changePassword,
};
