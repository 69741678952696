import apiClient from '../api';

export const createStore = async (payload) => {
  try {
    try {
      // eslint-disable-next-line no-param-reassign
      payload.creation_parameters = JSON.parse(localStorage.getItem('__parameters'));
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      payload.creation_parameters = {};
    }

    const { data } = await apiClient.stores.create(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const getStoreOfUser = async () => {
  try {
    const { data } = await apiClient.users.getStore();
    return {
      status: true,
      store: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const getStoreStatsOfUser = async () => {
  try {
    const { data } = await apiClient.stores.stats();
    return {
      status: true,
      stats: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const updateStore = async (payload) => {
  try {
    const { data } = await apiClient.stores.update(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
    return {
      status: false,
      msg: 'Error desconocido',
    };
  }
};

export default {
  createStore,
  getStoreOfUser,
  updateStore,
};
