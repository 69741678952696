/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import numeral from 'numeral';
import 'numeral/locales/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  Input, Button, Form, Alert, Col, Row, AutoComplete, Select,
} from 'antd';

import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';

import SwipeButton from '../../components/SwipeButton';

import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';

import {
  updateOrCreateOrder,
} from '../../services/orders';

import {
  getItems,
} from '../../services/items';

import {
  calculateTotal,
  computeOrderReference,
  computeShortHash,
} from '../../helpers/orders';
import {
  sendWhatsAppMessage,
} from '../../helpers/utils';

const { Option } = Select;

const flowSteps = {
  CREATING: ['NEW', 'No confirmada con el cliente'],
  NEW: ['CONFIRMED', 'Confirmada, No pagada'],
  CONFIRMED: ['PAID', 'Pagada'],
  PAID: ['DELIVERED', 'Entregada'],
};

const steps = {
  CONFIRMED: {
    label: 'Confirmada, No pagada',
    message: 'El pedido *{{order_reference}}* ha sido confirmado. A continuación las instrucciones para el pago\n\n{{payment_info}}\n\n*Total a pagar: {{total_price}}*',
  },
  PAID: {
    label: 'Pagado',
    message: 'El pago del pedido *{{order_reference}}* ha sido recibido. ¡Muchas gracias!',
  },
};

export const newOrder = {
  name: '',
  phone: '',
  email: null,
  status: 'NEW',
  rows: [{
    qty: 1,
  }],
  info: [],
  variants: [],
};

const OrderForm = ({
  startOrder,
  onChange,
  onFinish,
  onHideForm,
  onFinishFailed,
  store,
}) => {
  const [form] = Form.useForm();

  const [order, setOrder] = useState(startOrder || newOrder);
  const [loadingSave, setLoadingSave] = useState(false);
  const [modified, setModified] = useState(false);
  const [saved, setSaved] = useState(false);
  const [items, setItems] = useState(false);
  const [nextStep, setNextStep] = useState(null);
  const [stepInfo, setStepInfo] = useState(null);
  const [error, setError] = useState(false);

  useEffect(async () => {
    const rsp = await getItems('AVAILABLE,OUT_OF_STOCK,NON_AVAILABLE,HIDDEN');
    if (rsp.status) {
      setItems(rsp.items);
    }
  }, []);

  const calcNextStep = (currentStep) => {
    const s = flowSteps[currentStep];
    if (s) {
      return {
        value: s[0],
        label: s[1],
      };
    }
    return false;
  };

  const processMessage = (message) => {
    if (!message) {
      return message;
    }
    let m = message;
    m = m.replaceAll('{{payment_info}}', ((store && store.payment_info) || ''));
    m = m.replaceAll('{{order_hash}}', ((order && order.hash && computeShortHash(order.hash)) || ''));
    m = m.replaceAll('{{order_reference}}', ((order && order.reference) || computeOrderReference(order) || ''));
    m = m.replaceAll('{{total_price}}', (numeral(calculateTotal(order)).format('$0,000') || ''));
    return m;
  };
  useEffect(() => {
    setNextStep(calcNextStep(order.status));
    let si = steps[order.status];
    if (si && si.message) {
      si = {
        ...si,
        message: processMessage(si.message),
      };
    }
    setStepInfo(si);
  }, [order]);

  const onChangeOrder = () => {
    setModified(true);
    const o = {
      ...order,
      ...form.getFieldsValue(),
    };
    const total = calculateTotal(o);
    o.total_price = total;
    setOrder(o);
    if (onChange) { onChange(o); }
  };

  const onFinishOrder = async (values) => {
    setLoadingSave(true);
    const o = {
      ...order,
      ...values,
    };

    const rsp = await updateOrCreateOrder(o);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
      setModified(false);
      setOrder(rsp.orders);
      if (onFinish) { onFinish(rsp.order); }
    }
    if (rsp && !rsp.status) {
      setSaved(false);
      setError(rsp.msg);
    }
    setLoadingSave(false);
  };

  const onFinishFailedOrder = (values) => {
    const s = {
      ...order,
      ...values,
    };
    if (onFinishFailed) { onFinishFailed(s); }
  };

  const onChangeDetail = (index, detail, option) => {
    const formValues = form.getFieldsValue();
    if (option && option.item && option.item.offer_price) {
      formValues.rows[index].qty = formValues.rows[index].qty || 1;
      formValues.rows[index].unit_price = option.item.offer_price;
      formValues.rows[index].item_id = option.item.id;
      formValues.rows[index].item_type = 'ITEM';
      form.setFieldsValue(formValues);
    }
    onChangeOrder();
  };

  const moveAndSave = () => {
    const s = calcNextStep(order.status);
    if (s) {
      const o = form.getFieldsValue();
      o.status = s.value;
      form.setFieldsValue(o);
      onFinishOrder(form.getFieldsValue());
    }
  };

  return (
    <div className={`${stylesLayout.content} ${stylesForm.form} ${styles.form}`}>
      {error
        && (
          <div className={stylesForm.error}>
            <Alert message={error} type="error" />
          </div>
        )}

      <Form
        name="basic"
        form={form}
        initialValues={order}
        layout="horizontal"
        {...{
          labelCol: { span: 5 },
          wrapperCol: { span: 20 },
        }}
        onFinish={onFinishOrder}
        onValuesChange={onChangeOrder}
        onFinishFailed={onFinishFailedOrder}
        size="large"
      >
        <div className={stylesForm.header}>
          Información del pedido
          {' '}
          {order.hash ? `${computeShortHash(order.hash)}` : null}
          {' '}
        </div>
        <div className={styles.formContent}>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Status"
                name="status"
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Select>
                  <Option value="NEW">Pendiente de confirmación</Option>
                  <Option value="CONFIRMED">Confirmadas (pendientes de pago)</Option>
                  <Option value="PAID">Pagado</Option>
                  <Option value="DELIVERED">Entregado</Option>
                  <Option value="COMPLETED">Completado</Option>
                  <Option value="REFUNDED">Devuelto</Option>
                  <Option value="DELETED">Eliminado</Option>
                </Select>

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Número de pedido de la tienda"
                help="Si tienes algún numero de pedido interno puedes ponerlo aquí y usaremos ese número como referencia"
                name="internal_reference"
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Nombre del cliente"
                name="name"
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Teléfono del cliente"
                name="phone"
                help={(
                  <div>
                    {!order.phone && (
                    <span style={{ color: '#07bc4c', fontWeight: 'bold' }}>
                      Complétalo para poder comunicarte por WhatsApp
                      <br />
                    </span>
                    )}
                    Debes usar el formato correcto para poder enviar mensajes por WhatsApp.
                    {' '}
                    Ej: +56 9 XXXXXXXX (los espacios son opcionales)
                  </div>
                )}
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Email del cliente"
                name="email"
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Comentarios del pedido"
                name="comments"
                {...{
                  labelCol: { span: 5 },
                }}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <div className={styles.rows}>
            <Row>
              <Col sm={13} xs={12}>
                Descripción
                <br />
                <small>
                  Puedes buscar por el nombre de tus productos o
                  {' '}
                  servicios o escribir el detalle que quieras
                </small>
                <br />
                <br />
              </Col>
              <Col sm={5} xs={5}>
                Cantidad
              </Col>
              <Col sm={5} xs={5}>
                Precio por unidad
              </Col>
            </Row>
            <Form.List name="rows">
              {(rows, { add: addRow, remove: removeRow }) => (
                <>
                  {
                  rows.map((row, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={styles.variant} key={index}>
                      <Row gutter={4}>
                        <Col sm={13} xs={12}>
                          <Form.Item
                            name={[index, 'title']}
                          >
                            <AutoComplete
                              onSelect={(value, option) => {
                                onChangeDetail(index, value, option);
                                // addRow({ qty: 1, unit_price: 0 });
                              }}
                              options={items && items.map((item) => ({ value: item.title, item }))}
                              filterOption={(inputValue, option) => (
                                option
                                && option.value.toUpperCase().indexOf(inputValue.toUpperCase())
                                  !== -1
                              )}
                              placeholder="Detalle"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={5} xs={5}>
                          <Form.Item
                            name={[index, 'qty']}
                          >
                            <Input placeholder="Cantidad" type="number" min={1} align="center" />
                          </Form.Item>
                        </Col>
                        <Col sm={5} xs={5}>
                          <Form.Item
                            name={[index, 'unit_price']}
                          >
                            <Input placeholder="Precio por unidad" type="number" align="right" />
                          </Form.Item>
                        </Col>
                        <Col sm={1} xs={2} align="right">
                          <MinusCircleOutlined onClick={() => removeRow(index)} style={{ fontSize: '1.25rem', marginTop: 10, color: '#c30' }} />
                        </Col>
                      </Row>
                    </div>
                  ))
                }
                  <Row>
                    <Col span={24}>
                      <Button
                        type="dashed"
                        block
                        onClick={() => addRow({ qty: 1, unit_price: 0 })}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      >
                        <div style={{
                          padding: '20px 0 20px 0',
                        }}
                        >
                          <PlusCircleOutlined />
                          {' '}
                          Agregar item
                        </div>
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div className={styles.total}>
                    <Row>
                      <Col sm={6} xs={8} className={styles.label}>
                        Total
                      </Col>
                      <Col sm={18} xs={16} className={styles.value}>
                        $
                        {' '}
                        {numeral(order.total_price || 0).format('#,###')}
                      </Col>
                    </Row>
                  </div>
                </>
              )}

            </Form.List>
            <Row>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="Status"
                  name="status"
                  {...{
                    labelCol: { span: 5 },
                  }}
                >
                  <Select>
                    <Option value="NEW">Pendiente de confirmación</Option>
                    <Option value="CONFIRMED">Confirmadas (pendientes de pago)</Option>
                    <Option value="PAID">Pagado</Option>
                    <Option value="DELIVERED">Entregado</Option>
                    <Option value="COMPLETED">Completado</Option>
                    <Option value="REFUNDED">Devuelto</Option>
                    <Option value="DELETED">Eliminado</Option>
                  </Select>

                </Form.Item>
              </Col>
            </Row>
          </div>
          {isMobile()
          && (
          <Row>
            <Col sm={24} xs={24}>
              <div className={styles.save}>
                <div>
                  <Button type="secondary" htmlType="submit" disabled={loadingSave || !modified}>
                    Guardar
                  </Button>

                  <span className={`${stylesForm.saveMessage}`} onClick={onHideForm} style={{ cursor: 'pointer' }}>Cerrar</span>
                  {loadingSave && <span className={stylesForm.saveMessage}>Guardando...</span>}
                  {!loadingSave && saved && !modified && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          )}
          <div className={styles.actionBar}>
            <div className={styles.inner}>
              {!isMobile()
              && (
              <div className={styles.save}>
                <Form.Item>
                  <Button type="secondary" htmlType="submit" disabled={loadingSave || !modified}>
                    Guardar
                  </Button>

                  <span className={`${stylesForm.saveMessage}`} onClick={onHideForm} style={{ cursor: 'pointer' }}>Cerrar</span>
                  {loadingSave && <span className={stylesForm.saveMessage}>Guardando...</span>}
                  {!loadingSave && saved && !modified && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                  )}
                </Form.Item>
              </div>
              )}
              {(order.hash && nextStep && nextStep.label)
                && (
                <div className={styles.saveAndMove}>
                  <div>
                    {stepInfo && stepInfo.message && (
                    <Button
                      type="whatsapp"
                      icon={<FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: 10 }} />}
                      onClick={() => sendWhatsAppMessage(order.phone, stepInfo.message)}
                      disabled={!order.phone || loadingSave}
                      title={!order.phone ? 'Agrega un número de teléfono para poder enviar mensajes' : ''}
                    >
                      Enviar mensaje
                      {' '}
                      {stepInfo.label}
                    </Button>
                    )}
                    {isMobile()
                      ? (
                        <SwipeButton
                          key={order.status}
                          text={`${nextStep.label}`}
                          componentStyles={{ fontSize: 10 }}
                          color="#963D97"
                          status={1}
                          text_unlocked="Guardando..."
                          onSuccess={moveAndSave}
                          disabled={loadingSave}
                        />
                      )
                      : (
                        <Button type="primary" onClick={moveAndSave} disabled={loadingSave}>
                          Guardar como
                          {' '}
                          {nextStep.label}
                          <RightOutlined />
                        </Button>
                      )}

                  </div>
                </div>
                )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

OrderForm.defaultProps = {
  onChange: null,
  onFinish: null,
  onFinishFailed: null,
  onHideForm: null,
  store: null,
};

OrderForm.propTypes = {
  startOrder: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  onHideForm: PropTypes.func,
  store: PropTypes.object,
};

export default OrderForm;
