/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import numeral from 'numeral';
import 'numeral/locales/es';
// import PropTypes from 'prop-types';
// import styles from './index.module.scss';

import {
  Table, Input, Button, Space, Form, Alert, Col, Row, Radio, AutoComplete, Tabs, Badge, Switch, InputNumber, Select, notification,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';

import {
  FilterOutlined,
  DeleteOutlined,
  CopyOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import Loading from '../../components/Loading';
import Uploader from '../../components/Uploader';

import apiClient from '../../api';

import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import styles from './index.module.scss';

import {
  getItems,
  updateOrCreateItem,
} from '../../services/items';

import {
  getStoreOfUser,
} from '../../services/stores';

const { TabPane } = Tabs;

const statuses = [
  { value: 'AVAILABLE', label: 'Disponible' },
  { value: 'OUT_OF_STOCK', label: 'Sin Stock temporalmente' },
  { value: 'NON_AVAILABLE', label: 'No disponible' },
  { value: 'HIDDEN', label: 'Oculto (útil para costos de despacho y otros costos adicionales)' },
];
const ProductsModule = () => {
  numeral.locale('es');
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [item, setItem] = useState(null);
  const [store, setStore] = useState(null);
  const [showMoreInfoVariants, setShowMoreInfoVariants] = useState(false);

  const [items, setItems] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const searchInput = useRef(null);

  const emptyVariant = (id, name) => ({
  });
  const loadStore = async () => {
    const rsp = await getStoreOfUser();
    if (rsp.status) {
      setStore(rsp.store);
    }
  };
  const loadItems = async (showLoading = true) => {
    if (showLoading) { setLoadingItems(true); }
    const newItems = {};
    const rsp = await getItems('AVAILABLE,OUT_OF_STOCK,NON_AVAILABLE,HIDDEN');
    if (rsp.status) {
      newItems.active = rsp.items && rsp.items.map((i) => ({
        ...i,
        sectionName: i.section && i.section.name,
      }));
    }
    const rsp2 = await getItems('DELETED');
    if (rsp2.status) {
      newItems.deleted = rsp2.items && rsp2.items.map((i) => ({
        ...i,
        sectionName: i.section && i.section.name,
      }));
    }
    setItems(newItems);
    if (showLoading) { setLoadingItems(false); }
  };

  useEffect(async () => {
    loadItems();
    loadStore();
  }, []);

  const hideForm = () => {
    setShowForm(false);
  };

  const resetForm = () => {
    const newItem = {
      title: '',
      description: '',
      price: null,
      offer_price: null,
      sectionName: '',
      status: 'AVAILABLE',
      variants: [],

    };
    form.resetFields();
    form.setFieldsValue(newItem);
    setItem(newItem);
  };

  const showNewItemForm = () => {
    resetForm();
    setSaved(false);
    setShowForm(true);
  };

  const editItem = async (id) => {
    const selectedItem = items.active.find((x) => x.id === id);
    form.setFieldsValue(selectedItem);
    setShowForm(true);
    setItem(selectedItem);
  };

  const duplicateItem = async (id) => {
    const selectedItem = items.active.find((x) => x.id === id);
    delete selectedItem.id;
    delete selectedItem.slug;
    delete selectedItem.hash;
    form.setFieldsValue(selectedItem);
    setShowForm(true);
    setItem(selectedItem);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar..."
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const onChangeItem = () => {
    const s = {
      ...item,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setItem(s);
  };

  const deleteItem = async (id) => {
    const payload = {
      id,
      status: 'DELETED',
    };

    const newItems = {
      active: items.active.map((x) => (
        x.id === id ? null : x
      )).filter((x) => !!x),
      deleted: [
        ...items.deleted,
        items.active.find((x) => x.id === id),
      ],
    };
    setItems(newItems);

    const rsp = await updateOrCreateItem(payload);
    if (rsp && rsp.status) {
      loadItems(false);
    }
  };

  const setStatus = async (id, newStatus) => {
    console.log('setStatus', id, newStatus);
    const rsp = await updateOrCreateItem({
      id,
      status: newStatus,
    });
    if (!rsp?.status) {
      notification.error({
        message: 'Ocurrió un error al cambiar el estado.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const restoreItem = async (id) => {
    const payload = {
      id,
      status: 'NON_AVAILABLE',
    };

    const newItems = {
      active: [
        ...items.active,
        items.deleted.find((x) => x.id === id),
      ],
      deleted: items.deleted.map((x) => (
        x.id === id ? null : x
      )).filter((x) => !!x),
    };
    setItems(newItems);

    const rsp = await updateOrCreateItem(payload);
    if (rsp && rsp.status) {
      loadItems(false);
    }
  };

  const onFinishItem = async (values) => {
    setLoadingSave(true);
    setError(null);
    const s = {
      ...item,
      ...values,
    };
    if (s.offer_price > s.price) {
      setError(apiClient.items.getMessage("offer_price can't be greater than price"));
    }
    const rsp = await updateOrCreateItem(s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
      hideForm();
      loadItems();
      loadStore();
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setLoadingSave(false);
  };

  const onFinishFailedItem = () => {
    // console.log('Failed:', errorInfo);
  };

  const columns = [
    {
      title: 'Id',
      align: 'center',
      defaultSortOrder: 'desc',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('id'),
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id) => <a onClick={() => editItem(id)}>{id}</a>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      sorter: (a, b) => (a.status_tr < b.status_tr ? 1 : -1),
      render: (status, r) => (
        <>
          <Select
            options={statuses}
            style={{ width: 200, textAlign: 'left' }}
            defaultValue={status}
            onChange={(value) => { setStatus(r.id, value); }}
          />
        </ >
      ),
    },
    {
      title: '',
      dataIndex: 'image',
      align: 'center',
      key: 'image',
      render: (image) => (image ? <img src={image} alt="" className={styles.img} /> : ''),
    },
    {
      title: 'Sección',
      dataIndex: 'sectionName',
      key: 'sectionName',
      align: 'center',
      ...getColumnSearchProps('sectionName'),
      sorter: (a, b) => (a.sectionName < b.sectionName ? 1 : -1),
      render: (sectionName) => sectionName,
    },

    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => (a.title < b.title ? 1 : -1),
      render: (text) => text,
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
      sorter: (a, b) => (a.description < b.description ? 1 : -1),
      render: (text) => (text ? `${text.slice(0, 50)}${text.length > 50 ? '...' : ''}` : ''),
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      sorter: (a, b) => (a.offer_price || a.price) - (b.offer_price || b.price),
      render: (price, r) => (r.discount_percent !== 0 ? (
        <div style={{ textAlign: 'center' }}>
          $
          {numeral(r.offer_price).format('#,###')}
          <br />
          <small>
            (Normal: $
            {numeral(r.price).format('#,###')}
            )
          </small>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          $
          {numeral(r.price).format('#,###')}
        </div>
      )),

    },
  ];
  const columnsActive = [
    ...columns,
    {
      title: '',
      align: 'right',
      key: 'action',
      render: (_w, r) => (
        <Space size="middle">
          <div title="Editar" className={styles.action} onClick={() => editItem(r.id)}><EditOutlined style={{ fontSize: '1.25rem' }} /></div>
          <div title="Duplicar" className={styles.action} onClick={() => duplicateItem(r.id)}><CopyOutlined style={{ fontSize: '1.25rem' }} /></div>
          <div title="Borrar" className={styles.action} onClick={() => deleteItem(r.id)}><DeleteOutlined style={{ color: '#c30', fontSize: '1.25rem' }} /></div>
        </Space>
      ),
    },
  ];
  const columnsDeleted = [
    ...columns,
    {
      title: '',
      align: 'right',
      key: 'action',
      render: (_w, r) => (
        <Space size="middle">
          <div title="Restaurar" className={styles.action} onClick={() => restoreItem(r.id)}><RollbackOutlined style={{ color: '#c30', fontSize: '1.25rem' }} /></div>
          <div title="Duplicar" className={styles.action}><CopyOutlined style={{ fontSize: '1.25rem' }} /></div>
        </Space>
      ),
    },
  ];
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        {!showForm
          && (
            <div className={stylesLayout.secAction}>
              <Button type="primary" icon={<PlusOutlined />} onClick={showNewItemForm}>Agregar</Button>
            </div>
          )}

        Catálogo de la tienda
      </div>
      <div className={stylesLayout.subtitle}>
        Aquí puedes administrar los productos o servicios de tu tienda
      </div>
      {showForm
        && (
          <div className={`${stylesLayout.content} ${stylesForm.form} ${styles.form}`}>
            {error
              && (
                <div className={stylesForm.error}>
                  <Alert message={error} type="error" />
                </div>
              )}

            <Form
              name="basic"
              form={form}
              initialValues={item}
              layout="horizontal"
              {...{
                labelCol: { span: 2 },
                wrapperCol: { span: 22 },
              }}
              onFinish={onFinishItem}
              onValuesChange={onChangeItem}
              onFinishFailed={onFinishFailedItem}
              size="large"
            >
              <div className={stylesForm.header}>
                Información del producto o servicio
              </div>
              {item.id
                && (
                  <Form.Item
                    label="Id"
                    name="id"
                  >
                    <Input disabled />
                  </Form.Item>
                )}
              <Row>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Título"
                    name="title"
                    {...{
                      labelCol: { span: 4 },
                    }}
                    help="Intenta ser lo más explicativo posible"
                    rules={[{ required: true, message: 'El título es requerido' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Sección"
                    {...{
                      labelCol: { span: 4 },
                    }}
                    name="sectionName"
                    help="Las secciones permiten a los clientes encontrar tus productos más rápido. Elige una existente o escribe una nueva para crearla. Te recomendamos comenzar con mayúscula. Ej: Collares"
                    rules={[{ required: true, message: 'La sección es requerida' }]}
                  >
                    <AutoComplete
                      options={store && store.sections.map((x) => ({ value: x.name }))}
                      filterOption={(inputValue, option) => (
                        option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Descripción"
                    name="description"
                    {...{
                      labelCol: { span: 4 },
                    }}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Status"
                    name="status"
                    {...{
                      labelCol: { span: 4 },
                    }}
                    rules={[{ required: true, message: 'El status es requerido' }]}
                  >
                    <Radio.Group>
                      {statuses.map((status) => (
                        <Radio value={status.value}>{status.label}</Radio>
                      ))}
                    </Radio.Group>

                  </Form.Item>
                </Col>

              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Precio"
                    name="price"
                    {...{
                      labelCol: { span: 4 },
                    }}
                    rules={[{ required: true, message: 'El precio es requerido' }]}
                    help="Este es el precio habitual del producto o servicio"
                  >
                    <Input addonBefore="$" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Oferta"
                    {...{
                      labelCol: { span: 4 },
                    }}
                    name="offer_price"
                    help="Si quieres hacer una oferta pon el precio oferta aquí"
                  >
                    <Input addonBefore="$" />
                  </Form.Item>
                </Col>

              </Row>
              <Form.Item
                label="Imagen"
                name="image"
                help="Tamaño recomendado 400x400px. Menos de 500Kb. Formato JPG"
              >
                <Uploader
                  maxSizeMB={1}
                  label={item.image ? 'Cambiar imagen' : 'Subir'}
                  imageUrl={item.image}
                  onStartUpload={() => setUploadingImages(true)}
                  onUpload={(data) => {
                    setUploadingImages(false);
                    form.setFieldsValue({
                      image: data.filename,
                    });
                  }}
                />
              </Form.Item>

              <div className={stylesForm.header}>
                Variantes
              </div>
              <div className={stylesForm.subtitle}>
                Las variantes permiten que tus clientes puedan elegir tanto adicionales como alternativas de un producto o servicio.
                {' '}
                <a onClick={() => setShowMoreInfoVariants(true)}>Ver mas...</a>
                <br />
                <br />
                {showMoreInfoVariants
                  && (
                    <div>
                      <strong>
                        ¿Qué campos tienen las variantes?
                      </strong>
                      <br />
                      <br />
                      <strong>Título</strong>
                      : Este será el nombre de la variante (ejemplos: Horarios, Fechas, Color, Tamaño, Sabor, Agregados).
                      <br />
                      <strong>Obligatoriedad</strong>
                      : al marcar este campo se indica que tus clientes deberán elegir al menos una de las opciones de variante.
                      <br />
                      <strong>Cantidad de opciones para elegir</strong>
                      : Será la cantidad mínima y máxima de opciones de variante que tus clientes deben elegir antes de hacer su pedido. Si no hay mínimo o máximo puedes dejar el campo vacío.
                      <br />
                      <strong>Opciones de variante</strong>
                      : deberás agregar un nombre para cada alternativa o adicional, además esta puede tener un precio extra que se sumará al total del producto o servicio.
                      {' '}
                      Si en cambio, este no tiene costo para tu cliente, no se sumará nada más que el valor del producto o servicio.
                      <br />
                      <br />

                    </div>
                  )}
              </div>
              <div className={styles.variants}>
                <Form.List name="variants">
                  {(variants, { add: addVariant, remove: removeVariant }) => (
                    <>
                      {
                        variants.map((variant, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div className={styles.variant} key={index}>
                            <Row>
                              <Col sm={12} xs={24}>
                                <Form.Item
                                  label="Variante"
                                  {...{
                                    labelCol: { span: 4 },
                                  }}
                                  name={[index, 'name']}
                                >
                                  <Input placeholder="Título" />
                                </Form.Item>
                              </Col>
                              <Col sm={5}>
                                <Form.Item
                                  name={[index, 'mandatory']}
                                  label="Obligatorio"
                                  valuePropName="checked"
                                  {...{
                                    labelCol: { span: 16 },
                                  }}
                                >
                                  <Switch />

                                </Form.Item>
                              </Col>
                              <Col sm={3}>
                                <Form.Item
                                  label="Min"
                                  {...{
                                    labelCol: { span: 6 },
                                  }}
                                  name={[index, 'min']}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Col>
                              <Col sm={3}>
                                <Form.Item
                                  label="Max"
                                  {...{
                                    labelCol: { span: 6 },
                                  }}
                                  name={[index, 'max']}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Col>
                              <Col sm={1} align="right">
                                <MinusCircleOutlined onClick={() => removeVariant(variant.name)} style={{ fontSize: '1.25rem', marginTop: 10, color: '#c30' }} />
                              </Col>
                            </Row>
                            <div className={styles.options}>
                              <Form.List name={[index, 'options']}>
                                {(options, { add: addOption, remove: removeOption }) => (
                                  <>
                                    {
                                      options.map((option, index2) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div className={styles.option} key={index2}>
                                          <Row>
                                            <Col sm={{ offset: 2, span: 11 }} xs={{ span: 24 }}>
                                              <Form.Item
                                                name={[index2, 'name']}
                                              >
                                                <Input placeholder="Opcion..." />
                                              </Form.Item>
                                            </Col>

                                            <Col sm={5} xs={{ span: 16 }}>
                                              <Form.Item
                                                label="Precio adicional"
                                                {...{
                                                  labelCol: { span: 12 },
                                                }}
                                                name={[index2, 'price']}
                                              >
                                                <Input addonBefore="$" />
                                              </Form.Item>
                                            </Col>
                                            <Col sm={4} xs={{ span: 4 }} align="right">
                                              <Form.Item
                                                name={[index2, 'active']}
                                                label="Activo"
                                                valuePropName="checked"
                                                {...{
                                                  labelCol: { span: 16 },
                                                }}
                                              >
                                                <Switch />
                                              </Form.Item>

                                            </Col>
                                            <Col sm={2} xs={{ span: 4 }} align="right">
                                              <MinusCircleOutlined onClick={() => removeOption(option.name)} style={{ fontSize: '1.25rem', marginTop: 10, color: '#c30' }} />
                                            </Col>
                                          </Row>
                                        </div>
                                      ))
                                    }
                                    <Row>
                                      <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 22 }}>
                                        <Button
                                          type="dashed"
                                          onClick={() => addOption()}
                                          block
                                          style={{ width: '100%', padding: '30px 0 50px 0', display: 'block' }}
                                        >
                                          <PlusCircleOutlined />
                                          {' '}
                                          Agregar opción
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}

                              </Form.List>
                            </div>
                          </div>

                        ))
                      }
                      <Row>
                        <Col span={24}>
                          <Button
                            type="dashed"
                            block
                            onClick={() => addVariant()}
                            style={{ width: '100%', padding: '100px 0', display: 'block' }}
                          >
                            <PlusCircleOutlined />
                            {' '}
                            Agregar variante
                          </Button>
                        </Col>

                      </Row>
                    </>
                  )}

                </Form.List>

              </div>
              <Row>
                <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loadingSave || uploadingImages}>
                      Guardar
                    </Button>

                    <span className={`${stylesForm.saveMessage}`} onClick={hideForm} style={{ cursor: 'pointer' }}>Cerrar</span>
                    {loadingSave && <span className={stylesForm.saveMessage}>Guardando...</span>}
                    {!loadingSave && saved && (
                      <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                        <FontAwesomeIcon icon={faCheck} />
                        {' '}
                        Guardado
                      </span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      {!showForm && (
        <>
          {loadingItems && <Loading />}
          {!loadingItems && items && (items.active || items.deleted) && (
            <>
              <Tabs defaultActiveKey="active">
                <TabPane
                  tab={(
                    <>
                      Activos
                      <Badge count={(items && items.active) ? items.active.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="active"
                >
                  <Table columns={columnsActive} dataSource={items.active} pagination={{ pageSize: 50, hideOnSinglePage: true, position: ['bottomCenter'] }} />
                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Eliminados
                      <Badge count={(items && items.deleted) ? items.deleted.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="deleted"
                >
                  <Table columns={columnsDeleted} dataSource={items.deleted} pagination={{ pageSize: 50, hideOnSinglePage: true, position: ['bottomCenter'] }} />
                </TabPane>
              </Tabs>
            </>
          )}
        </>
      )}

    </div>
  );
};

export default ProductsModule;
