import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import styles from './index.module.scss';

const Metric = ({
  n, title, color, background, icon,
}) => (
  <div className={`${styles.cnt}  ${icon ? styles.withicon : null}`} style={{ color, background }}>
    {icon && (
      <div className={styles.icon}>{icon}</div>
    )}
    <div className={styles.info}>
      <div className={styles.n}>{!Number.isNaN(n) ? numeral(n).format('0,0') : n}</div>
      <div className={styles.title}>
        {title}
      </div>
    </div>
  </div>
);
Metric.defaultProps = {
  color: '',
  icon: null,
  background: null,
};

Metric.propTypes = {
  icon: PropTypes.any,
  color: PropTypes.string,
  n: PropTypes.any.isRequired,
  background: PropTypes.string,
  title: PropTypes.any.isRequired,
};

export default Metric;
