/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styles from './index.module.scss';

const Store = ({
  store,
}) => (
  <div className={styles.cnt}>
    <div
      className={styles.header}
      style={{
        backgroundImage: `url(${store.header_image})`,
        backgroundColor: store.color,
        borderBottomColor: store.color,
      }}
    >
      {store.header}
    </div>
    <div className={styles.info}>
      <div className={styles.logo}>
        {store.logo_image
          && <img src={store.logo_image} alt={store.name} />}
      </div>
      <div className={styles.center}>
        <div className={styles.name}>
          {store.name}

        </div>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: store.description && store.description.replaceAll && store.description.replaceAll('\n', '<br />') }} />
      </div>
      <div className={styles.icons}>
        <a
          href={`https://wa.me/${store.whatsapp}`}
          className={`${styles.icon} ${styles.whatsapp}`}
          title={store.whatsapp}
          style={{
            backgroundColor: store.color,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} fontSize={48} color={store.secondary_color} />
        </a>
      </div>
    </div>
  </div>

);

Store.defaultProps = {
  store: null,

};
Store.propTypes = {
  store: PropTypes.object,
};
export default Store;
