export default (client, defaultParams) => ({
  getAll: () => (
    client('/subscribers', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
