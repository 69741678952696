/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';

import {
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {
  getLandings,
  getLanding,
  updateBlock,
  updateFields,
  createLanding,
  addBlock,
  setBlocksOrder,
} from '../../services/landings';

import Loading from '../../components/Loading';
import LandingEditor, {
  newLanding,
} from '../../components/LandingEditor';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';
import LandingsTable from '../LandingsTable';

const LandingsModule = ({
  store,
}) => {
  const { slug } = useParams();
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState();
  const [loadingLandings, setLoadingLandings] = useState(false);
  const [landing, setLanding] = useState(null);
  const [landings, setLandings] = useState(null);
  const [pidSaving, setPidSaving] = useState({});
  const [pidSavingField, setPidSavingField] = useState(null);

  console.log('landing', landing);
  useEffect(() => {
    setLanding({
      ...landing,
      store,
    });
  }, [store]);

  const storeModifiedBlock = (newBlock) => {
    if (pidSaving[newBlock.id]) {
      clearTimeout(pidSaving[newBlock.id]);
    }
    const pid = window.setTimeout(() => {
      updateBlock(newBlock);
    }, 500);
    setPidSaving({
      ...pidSaving,
      [newBlock.id]: pid,
    });
  };

  const loadLandings = async (showLoading) => {
    if (showLoading) { setLoadingLandings(true); }
    const rsp = await getLandings();
    if (rsp.status) {
      setLandings(rsp.landings);
    }
    if (showLoading) { setLoadingLandings(false); }
  };
  const loadLanding = async (landingSlug) => {
    const rsp = await getLanding(landingSlug);
    if (rsp.status) {
      setLanding(rsp.landing);
    }
  };

  const editLanding = async (landingSlug) => {
    setLoadingLandings(true);
    await loadLanding(slug);
    setShowForm(true);
    setLoadingLandings(false);
  };

  const showNewLandingForm = () => {
    setLanding({
      ...newLanding,
      store,
      blocks: [],
    });
    setShowForm(true);
  };

  const hideForm = () => {
    setShowForm(false);
  };

  const onChangeLanding = (newLandingObject, autosave) => {
    setLanding({
      ...newLandingObject,
      store,
    });
    if (!autosave) {
      return;
    }
    setError(false);
    if (pidSavingField) {
      clearTimeout(pidSavingField);
    }
    const pid = window.setTimeout(async () => {
      if (landing && landing.id) {
        const rsp = await updateFields(landing.id, {
          data: newLandingObject.data,
          status: newLandingObject.status,
          title: newLandingObject.title,
          slug: newLandingObject.slug,
        });
        if (!rsp.status && rsp.msg) {
          setError(rsp.msg);
        }
      }
    }, 1000);
    setPidSavingField(pid);
  };
  const onSaveLanding = async (newLandingObject) => {
    setError(false);
    const rsp = await createLanding(newLandingObject);
    if (rsp.status && rsp.landing) {
      history.push(`/paginas/${rsp.landing.slug}`);
    }
    if (!rsp.status && rsp.msg) {
      setError(rsp.msg);
    }
  };
  const storeNewBlock = async (newBlock) => {
    await addBlock(newBlock);
    loadLandings();
  };

  const onSetBlocksOrder = async (blocks) => {
    setLanding({
      ...landing,
      store,
      blocks,
    });
    const ids = blocks.map((b) => b.id);
    setBlocksOrder(landing.id, ids);
  };

  useEffect(async () => {
    loadLandings(true);
  }, []);

  useEffect(async () => {
    if (slug && slug !== undefined) {
      editLanding(slug);
    } else {
      hideForm();
    }
  }, [landings, slug]);
  useEffect(async () => {
    loadLandings();
    if (!slug) {
      hideForm();
    }
  }, [slug]);

  return (
    <div className={`${stylesLayout.page} ${styles.cnt}`}>
      <div className={`${stylesLayout.title} ${styles.title}`}>
        {!showForm
          && (
            <div className={stylesLayout.secAction}>
              <Button type="primary" icon={<PlusOutlined />} onClick={showNewLandingForm}>Agregar</Button>
            </div>
          )}
        {showForm
          && (
            <div className={stylesLayout.secAction}>
              <Button type="secondary" icon={<LeftOutlined />} onClick={() => { hideForm(); history.push('/paginas'); }}>Ver todas las páginas</Button>
            </div>
          )}
        Páginas de la tienda
      </div>
      <div className={`${stylesLayout.subtitle} ${styles.title}`}>
        Aquí puedes administrar las páginas de tu tienda
      </div>
      {
        showForm && landing && landing.store
        && (
          <LandingEditor
            error={error}
            autosave={!!landing.id}
            landing={landing}
            onChangeBlock={storeModifiedBlock}
            onAddBlock={storeNewBlock}
            onChangeLanding={onChangeLanding}
            onSetOrder={onSetBlocksOrder}
            onSave={onSaveLanding}
          />
        )
      }
      {
        !showForm && (
          <>
            {loadingLandings && <Loading />}
            {!loadingLandings && landings
              && <LandingsTable landings={landings} onStartEditingLanding={(landingSlug) => { history.push(`/paginas/${landingSlug}`); }} />}
          </>
        )
      }

    </div>
  );
};

LandingsModule.defaultProps = {
  store: null,
};

LandingsModule.propTypes = {
  store: PropTypes.object,
};

export default LandingsModule;
