export default (client, defaultParams) => ({
  create: (payload) => {
    if (!payload.section_name && payload.sectionName) {
      // eslint-disable-next-line no-param-reassign
      payload.section_name = payload.sectionName;
    }
    return client('/order/create', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    });
  },
  getAll: (status) => (
    client(`/orders?status=${status}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id) => (
    client(`/order/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (payload) => {
    if (!payload.section_name && payload.sectionName) {
      // eslint-disable-next-line no-param-reassign
      payload.section_name = payload.sectionName;
    }
    return client(`/order/${payload.id}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    });
  },
  getMessage: (k) => {
    const messages = {
      "offer_price can't be greater than price": 'El precio oferta no puede ser mayor que el precio normal',
    };
    return (messages[k] || k);
  },
});
