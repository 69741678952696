/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  EditOutlined,
  FilterOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import {
  Input, Button, Table, Space,
} from 'antd';

import Highlighter from 'react-highlight-words';

import styles from './index.module.scss';

const LandingsTable = ({
  title,
  landings,
  onStartEditingLanding,
}) => {
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar..."
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      align: 'left',
      ...getColumnSearchProps('title'),
      sorter: (a, b) => (a.title < b.title ? 1 : -1),
      render: (a, r) => (
        <div>
          <a onClick={() => onStartEditingLanding(r.slug)}>
            {r.id}
            {' '}
            -
            {' '}
            {r.title}
          </a>
          <div>{moment.utc(r.created_at).local().format('DD/MM HH:mm')}</div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      align: 'center',
      ...getColumnSearchProps('status_tr'),
      sorter: (a, b) => (a.status_tr < b.status_tr ? 1 : -1),
      render: (a, r) => (
        <div>
          {r.status_tr}
        </div>
      ),
    },
    {
      title: 'Creada',
      dataIndex: 'created_at',
      align: 'right',
      sorter: (a, b) => (a.created_at < b.created_at ? 1 : -1),
      render: (a, r) => (
        <div>
          {moment.utc(r.created_at).local().format('DD/MM HH:mm')}
        </div>
      ),
    },
    {
      title: 'Modificada',
      dataIndex: 'updated_at',
      align: 'right',
      sorter: (a, b) => (a.updated_at < b.updated_at ? 1 : -1),
      render: (a, r) => (
        <div>
          {moment.utc(r.updated_at).local().format('DD/MM HH:mm')}
        </div>
      ),
    },
    {
      title: 'Enlace',
      dataIndex: 'url',
      align: 'left',
      ...getColumnSearchProps('url'),
      sorter: (a, b) => (a.url < b.url ? 1 : -1),
      render: (a, r) => (
        <a href={r.url} target="_blank" rel="noopener noreferrer">
          <LinkOutlined />
          {r.url}
        </a>
      ),
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      render: (_w, r) => (
        <Space size="middle">
          <div title="Editar" className={styles.action} onClick={() => onStartEditingLanding(r.slug)}><EditOutlined style={{ fontSize: '1.25rem' }} /></div>
        </Space>
      ),
    },
  ];
  return (
    <div className={styles.cnt}>
      {title && <div className={styles.title}>{title}</div>}
      <Table columns={columns} dataSource={landings} pagination={{ pageSize: 50, hideOnSinglePage: true, position: ['bottomCenter'] }} />
    </div>
  );
};
LandingsTable.defaultProps = {
  title: '',
  onStartEditingLanding: () => { },
};
LandingsTable.propTypes = {
  title: PropTypes.string,
  landings: PropTypes.array.isRequired,
  onStartEditingLanding: PropTypes.func,
};

export default LandingsTable;
