import apiClient from '../api';

export const getItems = async (status) => {
  try {
    const { data } = await apiClient.items.getAll(status);
    return {
      status: true,
      items: data,
    };
  } catch (e) {
    if (!e.response) {
      return {
        status: false,
      };
    }
    if (e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.items.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const updateOrCreateItem = async (payload) => {
  try {
    const { data } = await (
      !payload.id
        ? apiClient.items.create(payload)
        : apiClient.items.update(payload));

    return {
      status: true,
      items: data,
    };
  } catch (e) {
    console.log(e);
    if (!e.response) {
      return {
        status: false,
      };
    }
    if (e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.items.getMessage(e.response.data.error),
        };
      }
    }
  }

  return null;
};

export default {
  getItems,
  updateOrCreateItem,
};
