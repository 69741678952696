/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import BlankLayout from './layouts/BlankLayout';
import AdminLayout from './layouts/AdminLayout';

import DashboardModule from './modules/DashboardModule';
import StoreModule from './modules/StoreModule';
import ItemsModule from './modules/ItemsModule';
import OrdersModule from './modules/OrdersModule';
import CreateStoreModule from './modules/CreateStoreModule';
import LoginModule from './modules/LoginModule';
import ChangePasswordModule from './modules/ChangePasswordModule';
import LogoutModule from './modules/LogoutModule';
import LandingsModule from './modules/LandingsModule';
import QRModule from './modules/QRModule';
import SubscribersModule from './modules/SubsModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1)); // id=123
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <BlankLayout exact path="/login" component={LoginModule} />
        <BlankLayout exact path="/change_password/:token" component={ChangePasswordModule} />
        <BlankLayout exact path="/logout" component={LogoutModule} />
        <AdminLayout exact path="/" redirectTo="/dashboard" />
        <AdminLayout exact path="/dashboard" component={DashboardModule} />
        <AdminLayout exact path="/store" component={StoreModule} />
        <AdminLayout exact path="/tienda" component={StoreModule} />
        <AdminLayout exact path="/paginas" component={LandingsModule} />
        <AdminLayout exact path="/paginas/:slug" component={LandingsModule} />
        <AdminLayout exact path="/items" component={ItemsModule} />
        <AdminLayout exact path="/catalogo" component={ItemsModule} />
        <AdminLayout exact path="/orders" component={OrdersModule} />
        <AdminLayout exact path="/pedidos/:id" component={OrdersModule} />
        <AdminLayout exact path="/pedidos" component={OrdersModule} />
        <AdminLayout exact path="/pedidos/:id" component={OrdersModule} />
        <BlankLayout exact path="/crear-tienda" component={CreateStoreModule} />
        <AdminLayout exact path="/qr" component={QRModule} />
        <AdminLayout exact path="/suscriptores" component={SubscribersModule} />
      </Switch>
    </BrowserRouter>
  );
};

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
