import apiClient from '../api';

export const getLandings = async () => {
  try {
    const { data } = await apiClient.landings.getAll();
    return {
      status: true,
      landings: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const getLanding = async (slug) => {
  try {
    const { data } = await apiClient.landings.get(slug);
    return {
      status: true,
      landing: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const createLanding = async (landing) => {
  try {
    const { data } = await apiClient.landings.createLanding(landing);
    return {
      status: true,
      landing: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const updateBlock = async (block) => {
  try {
    const { data } = await apiClient.landings.updateBlock(block);
    return {
      status: true,
      block: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const updateFields = async (landingId, payload) => {
  try {
    console.log('updateFields');
    const { data } = await apiClient.landings.updateFields(landingId, payload);
    return {
      status: true,
      landing: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const setBlocksOrder = async (landingId, ids) => {
  try {
    const { data } = await apiClient.landings.setBlocksOrder(landingId, ids);
    return {
      status: true,
      block: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const addBlock = async (block) => {
  try {
    const { data } = await apiClient.landings.addBlock(block);
    return {
      status: true,
      block: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export default {
  getLandings,
  getLanding,
  updateBlock,
  addBlock,
  setBlocksOrder,
  updateFields,
};
