/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';

const QRModule = ({ store }) => (
  store && (
    <>
      <div className={stylesLayout.page}>
        <div className={stylesLayout.title}>
          QR de la tienda
        </div>
        <div className={stylesLayout.subtitle}>
          Haz click para verlos más grandes e imprimirlos
        </div>
        <div className={styles.list}>
          <a
            className={styles.item}
            href={`https://api.qoopit.io/public/store/qr/1/${store.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={`https://api.qoopit.io/public/store/qr/1/${store.slug}?s=small`} alt="Version 2" />
          </a>
          <a
            className={styles.item}
            href={`https://api.qoopit.io/public/store/qr/2/${store.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={`https://api.qoopit.io/public/store/qr/2/${store.slug}?s=small`} alt="Version 2" />
          </a>
          <a
            className={styles.item}
            href={`https://api.qoopit.io/public/store/qr/3/${store.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={`https://api.qoopit.io/public/store/qr/3/${store.slug}?s=small`} alt="Version 3" />
          </a>
          <a
            className={styles.item}
            href={`https://api.qoopit.io/public/store/qr/4/${store.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={`https://api.qoopit.io/public/store/qr/4/${store.slug}?s=small`} alt="Version 4" />
          </a>
          <a
            className={styles.item}
            href={`https://api.qoopit.io/public/store/qr/5/${store.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={`https://api.qoopit.io/public/store/qr/5/${store.slug}?s=small`} alt="Version 5" />
          </a>
        </div>
      </div>
    </>
  )
);

QRModule.propTypes = {
  store: PropTypes.object.isRequired,
};

export default QRModule;
