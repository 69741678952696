/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/es';

import {
  FilterOutlined,
} from '@ant-design/icons';

import {
  Input, Button, Table, Space,
} from 'antd';

import Highlighter from 'react-highlight-words';

import {
  faEnvelope, faPhone, faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  computeShortHash,
} from '../../helpers/orders';

import styles from './index.module.scss';

const OrdersTable = ({
  title,
  orders,
  onStartEditingOrder,
}) => {
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar..."
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = [
    {
      title: 'Pedido',
      align: 'center',
      defaultSortOrder: 'desc',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('hash'),
      render: (id, r) => (
        <div>
          <a onClick={() => onStartEditingOrder(r.id)}>
            {computeShortHash(r.hash)}
          </a>
          <div>{moment.utc(r.created_at).local().format('DD/MM HH:mm')}</div>
        </div>
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'email',
      align: 'left',
      ...getColumnSearchProps('email'),
      sorter: (a, b) => (a.name < b.name ? 1 : -1),
      render: (email, r) => (
        <div>
          {(!r.name && !r.email && !r.phone) && <div>Sin información del cliente</div>}
          {r.name
          && (
          <div>
            <FontAwesomeIcon icon={faUserAlt} />
            {' '}
            {r.name}
          </div>
          )}
          {r.phone
          && (
          <div>
            <FontAwesomeIcon icon={faPhone} />
            {' '}
            <a href={`https://wa.me/${r.phone}`} target="_blank" rel="noreferrer">{r.phone}</a>
          </div>
          )}
          {r.email && r.email.trim()
          && (
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
            {' '}
            <a href={`mailto:${r.email}`}>{r.email}</a>
          </div>
          )}
        </div>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total_price',
      align: 'right',
      key: 'total_price',
      sorter: (a, b) => (a.total_price - b.total_price),
      render: (total_price) => (
        <div style={{ textAlign: 'right' }}>
          $
          {numeral(total_price).format('#,###')}
        </div>
      ),

    },
  ];
  return (
    <div className={styles.cnt}>
      {title && <div className={styles.title}>{title}</div>}
      <Table columns={columns} dataSource={orders} pagination={{ pageSize: 50, hideOnSinglePage: true, position: ['bottomCenter'] }} />
    </div>
  );
};

OrdersTable.defaultProps = {
  title: null,
  orders: [],
  onStartEditingOrder: null,
};

OrdersTable.propTypes = {
  title: PropTypes.string,
  orders: PropTypes.array,
  onStartEditingOrder: PropTypes.func,
};

export default OrdersTable;
