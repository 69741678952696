import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Form, Dropdown, Input, Menu, Radio, Alert, Button, Row, Col,
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';

import slugify from 'slugify';
import stylesForm from '../../common/form.module.scss';
import styles from './index.module.scss';

import Block from '../Block';

export const newLanding = {
  title: '',
  slug: '',
  status: 'VISIBLE',
};

const LandingEditor = ({
  autosave,
  landing: landingProp,
  onChangeLanding,
  onChangeBlock,
  onAddBlock,
  onSetOrder,
  onSave,
  error,
}) => {
  const [landing, setLanding] = useState(landingProp);
  const [modifiedSlug, setModifiedSlug] = useState(false);
  const [blocks, setBlocks] = useState(landingProp && landingProp.blocks);
  useEffect(() => {
    if (!landingProp) {
      return;
    }
    setLanding(landingProp);
    setBlocks(landingProp.blocks);
  }, [landingProp]);

  const onChangeBlockInEditor = (id, newBlock) => {
    const newBlocks = blocks.map((b) => (b.id === id ? newBlock : b));
    onChangeBlock(newBlock);
    setBlocks(newBlocks);
  };
  const onChangeLandingFieldInEditor = (field, value) => {
    const newL = {
      ...landing,
      [field]: value,
    };
    if (field === 'slug') {
      setModifiedSlug(true);
    }
    if (field === 'title' && !modifiedSlug && !landing.id) {
      let newSlug = slugify(newL.title.toLowerCase() || '');
      if (newSlug && newSlug.replaceAll) {
        newSlug = newSlug.replaceAll('.', '-');
      } else {
        newSlug = '';
      }
      newL.slug = newSlug;
    }
    setLanding(newL);
    onChangeLanding(newL, autosave);
  };

  const handleAddTypeClick = (type) => {
    onAddBlock({
      landing_id: landing.id,
      type,
      hash: 'new-block',
    });
  };

  const handleOnDragEnd = (result) => {
    console.log('result', result);
    const newBlocks = [...blocks];
    const [reorderedItem] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, reorderedItem);
    onSetOrder(newBlocks);
  };

  const menu = (
    <Menu onClick={(e) => handleAddTypeClick(e.key)}>
      <Menu.Item key="button">Botón</Menu.Item>
      <Menu.Item key="header">Encabezado</Menu.Item>
      <Menu.Item key="social_networks">Redes Sociales</Menu.Item>
      <Menu.Item key="section">Sección del catálogo</Menu.Item>
    </Menu>
  );

  return (
    <div className={`${styles.cnt} ${stylesForm.form} ${styles.form}`}>
      {error
        && (
          <div className={stylesForm.error}>
            <Alert message={error} type="error" />
          </div>
        )}

      <Form.Item
        help="Intenta ser lo más explicativo posible"
        required
        rules={[{ required: true, message: 'El título es requerido' }]}
        label="Título"
        {...{
          labelCol: { span: isMobile() ? 24 : 2 },
        }}
      >
        <Input
          value={landing.title}
          required
          placeholder="Título de la página"
          onChange={(e) => { onChangeLandingFieldInEditor('title', e.target.value); }}
        />
      </Form.Item>
      <Form.Item
        label="Enlace"
        {...{
          labelCol: { span: isMobile() ? 24 : 2 },
        }}
      >
        <Input
          disabled={landing.id}
          addonBefore={`${landing.store.url}/p/`}
          value={landing.slug}
          onChange={(e) => { onChangeLandingFieldInEditor('slug', e.target.value); }}
        />
      </Form.Item>
      <Form.Item
        label="Status"
        {...{
          labelCol: { span: isMobile() ? 24 : 2 },
        }}
      >
        <Radio.Group
          value={landing.status}
          onChange={(e) => { onChangeLandingFieldInEditor('status', e.target.value); }}
        >
          <Radio value="VISIBLE">Visible</Radio>
          <Radio value="HIDDEN">Oculto</Radio>
        </Radio.Group>

      </Form.Item>
      {!autosave
        && (
          <Row>
            <Col offset={2}>
              <Button
                type="primary"
                disabled={!landing.title || !landing.slug}
                onClick={() => onSave(landing)}
              >
                {landing.id ? 'Guardar' : 'Crear'}

              </Button>

            </Col>
          </Row>
        )}
      {landing && landing.id && (
        <>
          <div className={stylesForm.header}>
            Contenido de la página
          </div>
          {(!blocks || blocks.length === 0)
            && (
              <div>
                Aún no hay contenido en esta página. Haz click en el botón + Agregar para empezar
              </div>
            )}

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="blocks">
              {(providedDroppable) => (
                <div
                  className={styles.blocks}
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {blocks.map((block, index) => (
                    <Draggable key={block.id} draggableId={block.hash} index={index}>
                      {(providedDraggable) => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                        >
                          <Block
                            block={block}
                            dragHandleProps={providedDraggable.dragHandleProps}
                            onChange={(newBlock) => onChangeBlockInEditor(block.id, newBlock)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Dropdown.Button
            type="dashed"
            size="large"
            overlay={menu}
            block
          >
            <PlusOutlined />
            Agregar
          </Dropdown.Button>
        </>
      )}
    </div>
  );
};
LandingEditor.defaultProps = {
  error: '',
  autosave: false,
  onChangeBlock: () => { },
  onAddBlock: () => { },
  onSetOrder: () => { },
  onChangeLanding: () => { },
  onSave: () => { },
};

LandingEditor.propTypes = {
  landing: PropTypes.array.isRequired,
  autosave: PropTypes.bool,
  error: PropTypes.string,
  onChangeBlock: PropTypes.func,
  onAddBlock: PropTypes.func,
  onSave: PropTypes.func,
  onSetOrder: PropTypes.func,
  onChangeLanding: PropTypes.func,
};

export default LandingEditor;
