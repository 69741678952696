/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'numeral/locales/es';

import { useParams, useHistory } from 'react-router-dom';

import {
  Button, Tabs, Badge,
} from 'antd';

import {
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import Loading from '../../components/Loading';
import OrderForm, { newOrder } from '../OrderForm';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';

import {
  getOrders,
} from '../../services/orders';

import OrdersTable from '../OrdersTable';

const { TabPane } = Tabs;

const OrdersModule = ({ store }) => {
  numeral.locale('es');

  const { id } = useParams();
  const history = useHistory();
  const [order, setOrder] = useState(null);

  const [orders, setOrders] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const loadOrders = async (showLoading = true) => {
    if (showLoading) { setLoadingOrders(true); }
    const tabs = {
      pending: 'NEW',
      confirmed: 'CONFIRMED',
      paid: 'PAID',
      completed: 'DELIVERED,COMPLETED',
      refunded: 'REFUNDED',
      deleted: 'DELETED',
    };
    const loadedOrders = await Promise.all(Object.values(tabs).map((s) => getOrders(s)))
      .then((data) => {
        const keys = Object.keys(tabs);
        const r = data.reduce((rr, v, i) => {
          const newR = {
            ...rr,
            all: v.status ? rr.all.concat(v.orders) : null,
            [keys[i]]: v.status ? v.orders : null,
          };
          return newR;
        }, { all: [] });
        return r;
      });
    setOrders(loadedOrders);
    if (showLoading) { setLoadingOrders(false); }
  };

  const hideForm = () => {
    setShowForm(false);
  };
  const hideFormAndReload = () => {
    hideForm();
    history.push('/pedidos');
  };

  const onFinishOrder = () => {
    hideForm();
    history.push('/pedidos');
  };

  const showNewOrderForm = () => {
    setOrder(newOrder);
    setShowForm(true);
  };

  const editOrder = async (orderId) => {
    const selectedOrder = orders.all.find((x) => x && x.id === parseInt(orderId, 10));
    setOrder(selectedOrder);
    setShowForm(true);
  };

  useEffect(async () => {
    if (id && id !== undefined && orders && orders.all) {
      editOrder(id);
    } else {
      hideForm();
    }
  }, [orders, id]);
  useEffect(async () => {
    loadOrders();
    if (!id) {
      hideForm();
    }
  }, [id]);
  return (
    <div className={`${stylesLayout.page} ${styles.cnt}`}>
      <div className={`${stylesLayout.title} ${styles.title}`}>
        {!showForm
          && (
            <div className={stylesLayout.secAction}>
              <Button type="primary" icon={<PlusOutlined />} onClick={showNewOrderForm}>Agregar</Button>
            </div>
          )}
        {showForm
          && (
            <div className={stylesLayout.secAction}>
              <Button type="secondary" icon={<LeftOutlined />} onClick={hideFormAndReload}>Ver todos los pedidos</Button>
            </div>
          )}

        Pedidos de la tienda
      </div>
      <div className={`${stylesLayout.subtitle} ${styles.title}`}>
        Aquí puedes administrar los pedidos de tu tienda
      </div>
      {showForm
        && (
          <OrderForm
            store={store}
            startOrder={order}
            onFinish={onFinishOrder}
            onHideForm={hideForm}
          />
        )}
      {!showForm && (
        <>
          {loadingOrders && <Loading />}
          {!loadingOrders && orders && (
            <>
              <Tabs defaultActiveKey="active">
                <TabPane
                  tab={(
                    <>
                      Pendientes de confirmacion
                      <Badge count={(orders && orders.pending) ? orders.pending.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="pending"
                >
                  <OrdersTable orders={orders.pending} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />
                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Pendientes de pago
                      <Badge count={(orders && orders.confirmed) ? orders.confirmed.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="confirmed"
                >
                  <OrdersTable orders={orders.confirmed} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />
                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Pagados
                      <Badge count={(orders && orders.paid) ? orders.paid.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="paid"
                >
                  <OrdersTable orders={orders.paid} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />
                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Entregados/Completos
                      <Badge count={(orders && orders.completed) ? orders.completed.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="completed"
                >
                  <OrdersTable orders={orders.completed} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />

                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Devueltos
                      <Badge count={(orders && orders.done) ? orders.done.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="refunded"
                >
                  <OrdersTable orders={orders.refunded} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />
                </TabPane>
                <TabPane
                  tab={(
                    <>
                      Eliminados
                      <Badge count={(orders && orders.deleted) ? orders.deleted.length : 0} showZero style={{ marginLeft: 5, backgroundColor: '#963D97' }} />
                    </>
                  )}
                  key="deleted"
                >
                  <OrdersTable orders={orders.deleted} onStartEditingOrder={(orderId) => { history.push(`/pedidos/${orderId}`); }} />
                </TabPane>
              </Tabs>
            </>
          )}
        </>
      )}

    </div>
  );
};

OrdersModule.defaultProps = {
  store: null,
};
OrdersModule.propTypes = {
  store: PropTypes.object,
};

export default OrdersModule;
