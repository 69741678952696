/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
} from 'antd';
import { API_BASE_URL } from '../../environment';

import styles from './index.module.scss';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Uploader = ({
  className,
  maxSizeMB,
  onStartUpload,
  onUpload,
  imageUrl,
  onError,
  accept,
  label,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(imageUrl);

  useEffect(() => {
    setUploadedImageUrl(imageUrl);
  }, [imageUrl]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const errors = [];
    if (!isJpgOrPng) {
      errors.push('La imagen debe ser JPG o PNG');
    }
    const isSmall = file.size / 1024 / 1024 < maxSizeMB;
    if (!isSmall) {
      errors.push(`El tamaño máximo de la imagen debe ser ${maxSizeMB}MB`);
    }
    if (errors && errors.length && errors.length > 0) {
      if (onError) {
        onError(errors);
      } else {
        // eslint-disable-next-line no-alert
        alert(errors.join('\n'));
      }
    }
    return isJpgOrPng && isSmall;
  };

  const handleChangeUpload = (field, info) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      onStartUpload();
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (rsp) => {
        setUploadedImageUrl(rsp);
        setUploading(false);
        if (onUpload) {
          onUpload({
            filename: info.file.response.url,
          });
        }
      });
    }
  };

  return (
    <Upload
      name="file"
      listType=""
      accept={accept}
      className={className}
      showUploadList={false}
      action={`${API_BASE_URL}/upload`}
      beforeUpload={beforeUpload}
      onChange={(info) => handleChangeUpload('logo', info)}
    >
      <div className={styles.cnt}>
        {uploading
          ? <div>Subiendo...</div>
          : (
            <>
              {uploadedImageUrl
                ? <img src={uploadedImageUrl} alt="" style={{ width: '100%' }} />
                : null}
              <div className={styles.btn}>
                {label}
              </div>
            </>
          )}
      </div>
    </Upload>
  );
};

Uploader.defaultProps = {
  className: '',
  label: 'Subir',
  imageUrl: null,
  maxSizeMB: 2,
  onStartUpload: () => { },
  onUpload: () => { },
  onError: null,
  accept: 'image/png, image/jpeg',
};
Uploader.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.string,
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  maxSizeMB: PropTypes.number,
  onUpload: PropTypes.func,
  onStartUpload: PropTypes.func,
  onError: PropTypes.func,
};
export default Uploader;
