/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Button, Alert, Row, Col,
} from 'antd';
import { SketchPicker } from 'react-color';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Store from '../../components/Store';
import Uploader from '../../components/Uploader';
import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import styles from './index.module.scss';

import {
  getStoreOfUser,
  updateStore,
} from '../../services/stores';

const StoreModule = () => {
  const [form] = Form.useForm();
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState();

  useEffect(async () => {
    const rsp = await getStoreOfUser();
    if (rsp.status) {
      setStore(rsp.store);
    }
  }, []);
  if (!store) {
    return null;
  }

  const onChange = () => {
    const s = {
      ...store,
      ...form.getFieldsValue(),
    };
    if (s.color && s.color.hex) {
      s.color = s.color.hex;
    }
    if (s.secondary_color && s.secondary_color.hex) {
      s.secondary_color = s.secondary_color.hex;
    }
    setSaved(false);
    setStore(s);
  };
  // eslint-disable-next-line no-unused-vars
  const onFinish = async (values) => {
    setLoading(true);
    setError(null);
    const s = {
      ...store,
      ...values,
    };
    const rsp = await updateStore(s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    setLoading(false);
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Información de la tienda
      </div>
      <div className={stylesLayout.subtitle}>
        Aquí podrás hacer ajustes en tu tienda.
      </div>
      {error
        && (
          <div className={stylesForm.error}>
            <Alert message={error} type="error" />
          </div>
        )}
      <div className={styles.cnt}>
        <div className={`${styles.form} ${stylesForm.form}`}>
          <div className={stylesForm.fields}>
            <Form
              name="basic"
              form={form}
              initialValues={store}
              layout="vertical"
              onFinish={onFinish}
              onFieldsChange={onChange}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Form.Item
                label="Nombre de tu tienda"
                name="name"
                rules={[{ required: true, message: 'El Nombre de tu tienda es requerido' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Descripción"
                name="description"
              >
                <Input.TextArea rows={3} type="text" />
              </Form.Item>
              <Form.Item
                label="Reseña"
                name="remark"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Palabras clave"
                name="keywords"
                help="Puedes agregar algunas palabras relacionadas con tu tienda que ayuden a los clientes a encontrarla en Google. Separa los términos por comas. "
              >
                <Input />
              </Form.Item>
              <div className={stylesLayout.sectionHeader}>
                Personaliza tu tienda
              </div>

              <Row>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Color Principal"
                    name="color"
                    valuePropName="color"
                  >
                    <SketchPicker
                      onChange={(data) => {
                        form.setFieldsValue({
                          color: ((data !== null && data.hex) ? data.hex : '#666'),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Color Secundario"
                    name="secondary_color"
                    valuePropName="color"
                  >
                    <SketchPicker
                      onChange={(data) => {
                        form.setFieldsValue({
                          secondary_color: ((data !== null && data.hex) ? data.hex : '#fff'),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Logo"
                    name="logo_image"
                    help="Tamaño recomendado 400x400 px. Menos de 500kb"
                  >
                    <Uploader
                      maxSizeMB={1}
                      imageUrl={store.logo_image}
                      label={store.logo_image ? 'Cambiar imagen' : 'Subir'}
                      onUpload={(data) => {
                        form.setFieldsValue({
                          logo_image: data.filename,
                        });
                        onChange();
                      }}
                    />

                  </Form.Item>
                  {store.logo_image && (
                    <a
                      style={{ display: 'inline-block', marginBottom: '20px' }}
                      onClick={() => {
                        form.setFieldsValue({
                          logo_image: '',
                        });

                        setStore((prevStore) => ({
                          ...prevStore,
                          logo_image: '',
                        }));
                      }}
                    >
                      Eliminar imagen
                    </a>
                  )}
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Imagen de encabezado"
                    name="header_image"
                    help="Tamaño recomendado 1200x300 px. Menos de 500Kb"
                  >
                    <Uploader
                      maxSizeMB={1}
                      imageUrl={store.header_image}
                      label={store.header_image ? 'Cambiar imagen' : 'Subir'}
                      onUpload={(data) => {
                        form.setFieldsValue({
                          header_image: data.filename,
                        });
                        onChange();
                      }}
                    />
                  </Form.Item>
                  {store.header_image && (
                    <a
                      style={{ display: 'inline-block', marginBottom: '20px' }}
                      onClick={() => {
                        form.setFieldsValue({
                          header_image: '',
                        });

                        setStore((prevStore) => ({
                          ...prevStore,
                          header_image: '',
                        }));
                      }}
                    >
                      Eliminar imagen
                    </a>
                  )}
                </Col>
              </Row>
              {['test', 'pro'].includes(store.plan)
                && (
                  <>
                    <Form.Item
                      label="Header"
                      name="custom_scripts_header"
                      help="Acá puedes poner estilos o cargar imagenes que personalicen tu sitio. Lo que agregues aquí se agregará en la etiqueta <header> del HTML."
                    >
                      <Input.TextArea rows={15} type="text" style={{ fontSize: 11, fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New'" }} />
                    </Form.Item>
                  </>
                )}

              <Form.Item
                label="Número de WhatsApp"
                name="whatsapp"
                help="+56 9 xxxx xxxx. Este número será donde te contacten los clientes."
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Instagram"
                name="instagram"
                help="Si tienes instagram puedes agregarlo aquí. Solo el usuario"
              >
                <Input addonBefore="@" />
              </Form.Item>
              <Form.Item
                label="Facebook"
                name="facebook"
                help="Si tienes facebook puedes agregarlo aquí. Solo el usuario"
              >
                <Input addonBefore="facebook.com/" />
              </Form.Item>
              <Form.Item
                label="TikTok"
                name="tiktok"
                help="Si tienes tiktok puedes agregarlo aquí. Solo el usuario"
              >
                <Input addonBefore="@" />
              </Form.Item>
              <Form.Item
                label="Twitter"
                name="twitter"
                help="Si tienes twitter puedes agregarlo aquí. Solo el usuario"
              >
                <Input addonBefore="@" />
              </Form.Item>
              <Form.Item
                label="LinkedIn"
                name="linkedin"
                help="Si tienes linkedin puedes agregarlo aquí. Solo el usuario"
              >
                <Input addonBefore="www.linkedin.com/company/" />
              </Form.Item>
              <Form.Item
                label="Id de Medición de Google Analytics"
                name="analytics_id"
                help={(
                  <span>
                    Si tienes cuenta de Analytics puedes agregar su Id de
                    {' '}
                    Medición aqui (El que empieza con G-). Puedes ver más
                    {' '}
                    <a rel="noreferrer" href="https://support.google.com/analytics/answer/7476135?hl=es" target="_blank">aquí</a>
                  </span>
                )}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Id de Pixel de Facebook"
                name="fb_pixel_id"
                help={(
                  <span>
                    El Id del Pixel es el número XXXXXXXX que aparece en la linea que dice
                    <br />
                    <code>fbq(&apos;init&apos;, &apos;XXXXXXXX&apos;);</code>
                  </span>
                )}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Id de Verificación de Dominio de Facebook"
                name="fb_domain_verification"
                help={(
                  <span>
                    Aquí puedes poner el content del tag
                    <br />
                    <br />
                    {' '}
                    <code>
                      &lt;meta
                      {' '}
                      name=&quot;facebook-domain-verification&quot; content=&quot;...&quot; /&gt;
                      {' '}
                    </code>
                    <br />
                    <br />
                    que requiere Facebook para la verificación del dominio
                  </span>
                )}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Información de pago"
                name="payment_info"
                help="Acá puedes agregar todos los datos que tus clientes necesiten para hacer el pago y completar el pedido. Solamente lo verán cuando confirmes que puedes vender el pedido."
              >
                <Input.TextArea rows={3} />
              </Form.Item>
              <div className={styles.actionBar}>
                <div className={styles.inner}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                      Guardar
                    </Button>
                    {loading && <span className={stylesForm.saveMessage}>Guardando...</span>}
                    {!loading && saved && (
                      <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                        <FontAwesomeIcon icon={faCheck} />
                        {' '}
                        Guardado
                      </span>
                    )}
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className={styles.preview}>
          <div className={stylesLayout.sectionHeader}>
            Así se verá tu tienda
          </div>
          <div className={styles.inner}>
            <Store store={store} />
          </div>
        </div>
      </div>

    </div>
  );
};

StoreModule.defaultProps = {
};

StoreModule.propTypes = {
};

export default StoreModule;
