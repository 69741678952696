import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import { DragOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import ButtonBlock from './Types/ButtonBlock';
import HeaderBlock from './Types/HeaderBlock';
import SocialNetworks from './Types/SocialNetworks';
import SectionBlock from './Types/SectionBlock';

const titles = {
  button: 'Botón',
  header: 'Encabezado',
  social_networks: 'Redes sociales',
  section: 'Sección',
};

const blockTypes = {
  button: ButtonBlock,
  header: HeaderBlock,
  social_networks: SocialNetworks,
  section: SectionBlock,
};

const Block = ({
  block: propBlock,
  onChange,
  dragHandleProps,
}) => {
  const [block, setBlock] = useState(propBlock);
  useEffect(() => {
    if (!propBlock) {
      return;
    }
    setBlock(block);
  }, [propBlock]);
  const onChangeData = (newBlock) => {
    setBlock(newBlock);
    onChange(newBlock);
  };
  const getBlock = (bl) => {
    if (!blockTypes[bl.type]) {
      return null;
    }
    return React.createElement(blockTypes[bl.type], { block: bl, onChange: onChangeData });
  };
  const onChangeStatus = (checked) => {
    const newBlock = {
      ...block,
      status: checked ? 'VISIBLE' : 'HIDDEN',
    };
    onChangeData(newBlock);
  };
  const renderedBlock = getBlock(block);
  if (!renderedBlock) {
    return null;
  }
  return (
    <div className={`${styles.block} ${block.status === 'VISIBLE' ? styles.visible : styles.hidden}`}>
      <div className={styles.title}>
        <div className={styles.handler} {...dragHandleProps}><DragOutlined /></div>
        <div className={styles.lbl}>
          {titles[block.type]}
        </div>
        <div className={styles.switch}>
          <Switch defaultChecked={block.status === 'VISIBLE'} onChange={onChangeStatus} />
        </div>
      </div>
      <div className={styles.body}>
        {renderedBlock}
      </div>
    </div>
  );
};
Block.defaultProps = {
  onChange: () => { },
};

Block.propTypes = {
  block: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dragHandleProps: PropTypes.object.isRequired,
};

export default Block;
