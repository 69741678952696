export default (client, defaultParams) => ({
  getStore: (payload) => (
    client('/user/store', {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  login: (payload) => (
    client('/user/login', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  forgetPassword: (payload) => (
    client('/user/forget_password', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  changePassword: (payload) => (
    client('/user/change_password', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      no_login: 'Email o contraseña incorrecta',
    };
    return (messages[k] || k);
  },
});
