import apiClient from '../api';

export const getAll = async () => {
  try {
    const { data } = await apiClient.subscribers.getAll();
    return {
      status: true,
      subscribers: data,
    };
  } catch (e) {
    if (e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.stores.getMessage(e.response.data.error),
        };
      }
    }
  }
  return null;
};

export default {
  getAll,
};
