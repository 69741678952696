/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/es';

import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram, faFacebook, faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import {
  CheckCircleOutlined, QrcodeOutlined,
} from '@ant-design/icons';
import Loading from '../../components/Loading';
import { RenderLineChart } from '../../components/Charts';

import {
  getStoreOfUser,
  getStoreStatsOfUser,
} from '../../services/stores';

import {
  getOrders,
} from '../../services/orders';

import {
  getItems,
} from '../../services/items';

import Metric from '../../components/Metric';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import OrdersTable from '../OrdersTable';

numeral.locale('es');

const plans = {
  test: 'Test',
  // 'basic': 'Básico',
  basic: 'Intermedio',
  pro: 'Pro',
};
const getStepsStore = (store) => {
  const steps = [];

  steps.push({
    field: 'name',
    message: 'Agrega el nombre de tu tienda',
    completed: !!store.name,
  });

  steps.push({
    field: 'description',
    message: 'Define una descripción',
    completed: !!store.description,
  });

  steps.push({
    field: 'remark',
    message: 'Completa una reseña para tu tienda',
    completed: !!store.remark,
  });

  steps.push({
    field: ['logo_image'],
    message: 'Agrega un logo',
    completed: !!store.logo_image,
  });

  steps.push({
    field: ['header_image'],
    message: 'Agrega una imagen para el encabezado',
    completed: !!store.header_image,
  });

  steps.push({
    field: 'whatsapp',
    message: 'Agrega el número de WhatsApp',
    completed: !!store.whatsapp,
  });

  steps.push({
    field: 'whatsapp',
    message: 'Agrega los links de tus redes sociales',
    completed: !!(store.facebook || store.instagram || store.twitter),
  });

  steps.push({
    field: 'payment_info',
    message: 'Información de pago',
    completed: !!store.payment_info,
  });

  return steps;
};

const DashboardModule = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);
  const [store, setStore] = useState(null);
  const [items, setItems] = useState(null);

  const loadStore = async () => {
    const [rspStore, rspStats] = await Promise.all([
      getStoreOfUser(),
      getStoreStatsOfUser(),
    ]);
    if (rspStore.status) {
      setStore({
        ...rspStore.store,
        ...{
          stats: rspStats.status && rspStats.stats,
        },
      });
    }
  };

  const loadItems = async () => {
    const rsp = await getItems('AVAILABLE,OUT_OF_STOCK,NON_AVAILABLE,HIDDEN');
    if (rsp) {
      setItems(rsp.items);
    }
  };

  const loadOrders = async () => {
    const tabs = {
      new: 'NEW',
      confirmed: 'CONFIRMED',
      paid: 'PAID',
      completed: 'DELIVERED,COMPLETED',
      refunded: 'REFUNDED',
      deleted: 'DELETED',
    };

    const loadedOrders = await Promise.all(Object.values(tabs).map((s) => getOrders(s)))
      .then((data) => {
        const keys = Object.keys(tabs);
        const r = data.reduce((rr, v, i) => {
          const newR = {
            ...rr,
            all: v.status ? rr.all.concat(v.orders) : [],
            [keys[i]]: v.status ? v.orders : [],
          };
          return newR;
        }, { all: [] });
        return r;
      });
    setOrders(loadedOrders);
  };

  useEffect(async () => {
    setLoading(true);
    loadStore();
    loadOrders();
    loadItems();
    setLoading(false);
  }, []);

  const viewQr = () => {
    history.push('/qr');
  };

  if (loading || !store || !orders) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  const hasOrders = orders && orders.all && orders.all.length > 0;
  const hasItems = items && items.length > 0;
  const stepsStore = getStepsStore(store);
  const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        {store.name}
      </div>
      <div className={stylesLayout.subtitle}>
        Aquí encontrarás información general sobre el estado de tu tienda.
      </div>

      <Row gutter={24}>
        <Col sm={16} xs={24}>
          {hasOrders && (
            <div style={{ marginBottom: 40 }}>
              <div>
                <Row gutter={12}>
                  <Col sm={8} xs={12}>
                    <Metric
                      background="#f5f5f5"
                      color="#424242"
                      n={orders.new.length}
                      title="Pend. confirmación"
                      xicon={(
                        <div style={{
                          background: '#56CCF2', padding: '20px', borderRadius: '10px', display: 'inline-block',
                        }}
                        >
                          <img alt="" src="https://assets.qoopit.io/icons/ic_orders.png" />
                        </div>
                      )}
                    />
                    <br />
                  </Col>
                  <Col sm={8} xs={12}>
                    <Metric
                      background="#f5f5f5"
                      color="#424242"
                      n={orders.new.length}
                      title="Pend. confirmación"
                      xicon={(
                        <div style={{
                          background: '#56CCF2', padding: '20px', borderRadius: '10px', display: 'inline-block',
                        }}
                        >
                          <img alt="" src="https://assets.qoopit.io/icons/ic_orders.png" />
                        </div>
                      )}
                    />
                    <br />
                  </Col>
                  <Col sm={8} xs={12}>
                    <Metric
                      background="#f5f5f5"
                      color="#424242"
                      n={orders.confirmed.length}
                      title="Pend. pago"
                      xicon={(
                        <div style={{
                          background: '#56CCF2', padding: '20px', borderRadius: '10px', display: 'inline-block',
                        }}
                        >
                          <img alt="" src="https://assets.qoopit.io/icons/ic_orders.png" />
                        </div>
                      )}
                    />
                    <br />
                  </Col>
                  <Col sm={8} xs={24}>
                    <Metric
                      background="#f5f5f5"
                      color="#424242"
                      n={orders.paid.length}
                      title="Pend. cumplimiento"
                      xicon={(
                        <div style={{
                          background: '#56CCF2', padding: '20px', borderRadius: '10px', display: 'inline-block',
                        }}
                        >
                          <img alt="" src="https://assets.qoopit.io/icons/ic_orders.png" />
                        </div>
                      )}
                    />
                    <br />
                  </Col>
                  <Col sm={8} xs={24}>
                    <Metric
                      background="#f5f5f5"
                      color="#424242"
                      n={numeral(
                        (orders.paid?.reduce((a, x) => a + x.total_price, 0) || 0)
                          + (orders.completed?.reduce((a, x) => a + x.total_price, 0) || 0),
                      ).format('0,000')}
                      title="Monto vendido"
                      xicon={(
                        <div style={{
                          background: '#56CCF2', padding: '20px', borderRadius: '10px', display: 'inline-block',
                        }}
                        >
                          <img alt="" src="https://assets.qoopit.io/icons/ic_orders.png" />
                        </div>
                      )}
                    />
                    <br />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {store
            && (['test'].includes(store.plan) || (store.stats && store.stats.users_views && store.stats.users_views.length >= 4))
            && (store.stats && store.stats.users_views)
            && (
              <RenderLineChart
                data={store.stats.users_views}
                yAxisScale="linear"
                xAxisType="linear"
                yAxisMin={0}
                width={100}
                height={30}
                xLabelsField="day"
                yDatasets={{
                  Visitantes: 'users',
                  Vistas: 'views',
                }}
              />
            )}
          {hasOrders && (
            <>
              {orders && orders.new && orders.new.length > 0 && (
                <>
                  <Row>
                    <Col span={24}>
                      <OrdersTable orders={orders.new} onStartEditingOrder={(id) => { history.push(`/pedidos/${id}`); }} title="Pedidos pendientes de confirmación al cliente" />
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              {orders && orders.confirmed && orders.confirmed.length > 0 && (
                <>
                  <Row>
                    <Col span={24}>
                      <OrdersTable orders={orders.confirmed} onStartEditingOrder={(id) => { history.push(`/pedidos/${id}`); }} title="Pedidos pendientes de pago" />
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              {orders && orders.paid && orders.paid.length > 0 && (
                <>
                  <Row>
                    <Col span={24}>
                      <OrdersTable orders={orders.paid} onStartEditingOrder={(id) => { history.push(`/pedidos/${id}`); }} title="Pedidos pendientes de cumplimiento (no entregados o no realizados)" />
                    </Col>
                    <br />
                  </Row>
                </>

              )}
            </>
          )}

          {pendingStepsStore.length > 2 && (
            <div className={`${styles.storeSteps} ${styles.box} ${styles.warning}`}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                Completa la información de tu tienda
              </div>
              <div className={styles.info}>
                Estas a punto de comenzar a vender y mientras más información agregues a
                {' '}
                tu tienda más bacán, chida, guay, chévere o genial será.
              </div>
              <ul>
                {stepsStore.map((step) => (
                  <li className={`${styles.storeStep} ${step.completed ? styles.completed : null}`}>
                    {step.completed
                      ? (
                        <div>
                          <CheckCircleOutlined />
                          {' '}
                          <span>{step.message}</span>
                        </div>
                      )
                      : (
                        <div>
                          <CheckCircleOutlined style={{ visibility: 'hidden' }} />
                          {' '}
                          <Link to="/tienda">{step.message}</Link>
                        </div>
                      )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {(pendingStepsStore.length <= 2) && !hasItems && (
            <div className={`${styles.noItems} ${styles.box} ${styles.warning}`}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                Agrega los productos o servicios que quieras vender a tu catálogo.
              </div>
              <div className={styles.info}>
                Aún no tienes productos o servicios disponibles para los visitantes de tu tienda.
                <br />
                <br />
                <Link to="/items"><strong>Empieza a crear tu catálogo aquí</strong></Link>
                <br />
                <br />
                También podrás conectar tu catálogo con Instagram o Facebook.
              </div>
            </div>
          )}
          {(pendingStepsStore.length <= 2) && hasItems && !hasOrders && (
            <div className={`${styles.noOrders} ${styles.box} ${styles.warning}`}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                Aún no tienes pedidos.
              </div>
              En la sección
              {' '}
              <Link to="/pedidos"><strong>Pedidos</strong></Link>
              {' '}
              verás todos los pedidos que tus clientes realicen por medio de tu tienda.
              {' '}
              Allí, podrás gestionar su pago, agregar más productos o servicios,
              {' '}
              adicionar cobros extras como despacho o lo que quieras.
              <br />
              <br />
              También podrás completar la información de tus clientes como Nombre,
              {' '}
              Teléfono y Email, esto te será muy útil para generar nuevas ventas en el futuro.
              <br />
              <br />
              ¿Lo mejor? Si recibes pedidos directamente en tu WhatsApp o
              {' '}
              vía Redes Sociales también puedes
              {' '}
              <Link to="/pedidos"><strong>agregarlos</strong></Link>
              {' '}
              para gestionar todo desde un solo lugar.
            </div>
          )}
        </Col>
        <Col sm={8} xs={24}>
          {store
            && (
              <div className={`${styles.box} ${styles.qr}`}>

                <div className={styles.header}>
                  Tu tienda
                </div>
                <div className={styles.info}>
                  Puedes ver tu tienda aquí:
                  <br />
                  <br />
                  <strong><a href={store.url} target="_blank" rel="noreferrer">{store.url}</a></strong>
                  <br />
                  <br />
                  Puedes usar este link en Facebook, Instagram o donde quieras publicitarlo.
                  <br />
                  <br />
                  Tienes el plan
                  {' '}
                  <strong>{plans[store.plan]}</strong>
                  {' '}
                  gratis por
                  {' '}
                  <strong>12 meses</strong>
                  <br />
                  <small>
                    * Válido hasta el
                    {' '}
                    {moment(store.created_at).add(1, 'year').format('DD/MM/YYYY')}
                  </small>
                </div>
              </div>
            )}
          {store
            && (
              <div className={`${styles.box} ${styles.qr}`}>

                <div className={styles.header}>
                  QR de tu tienda
                </div>
                <div className={styles.info}>
                  Aquí puedes descargar distintos diseños para mostrar
                  {' '}
                  tu código QR en tus redes sociales, locales, mesas o donde quieras.
                  <br />
                  <br />
                  <Button type="primary" icon={<QrcodeOutlined />} onClick={viewQr}>Ver todos los diseños</Button>
                </div>
              </div>
            )}
          {store
            && (
              <div className={`${styles.box} ${styles.feed}`}>

                <div className={styles.header}>
                  Catálogo de Facebook o Instagram
                </div>
                <div className={styles.info}>
                  ¿Quieres subir tus productos al catálogo de Facebook o Instagram?
                  <br />
                  <br />
                  Puedes usar esta URL cuando te lo pidan:
                  <br />
                  <br />
                  <a href={`https://api.qoopit.io/public/store/feed/${store.slug}`} target="_blank" rel="noreferrer">
                    <small>
                      https://api.qoopit.io/public/store/feed/
                      {store.slug}
                    </small>
                  </a>
                </div>
                <div>
                  Descubre más información en la documentación de
                  {' '}
                  <a href="https://www.facebook.com/business/help/125074381480892?id=725943027795860" target="_blank" rel="noreferrer">Facebook</a>
                  {' '}
                  y de
                  {' '}
                  <a href="https://www.facebook.com/business/instagram/shopping/guide" target="_blank" rel="noreferrer">Instagram</a>
                </div>
              </div>
            )}
          <div className={`${styles.box} ${styles.help}`}>

            <div className={styles.header}>
              ¿Necesitas ayuda?
            </div>
            <div className={styles.info}>
              Puedes escribir por nuestro chat, aquí abajo.
            </div>
            <ul>
              <li><a href="https://www.instagram.com/qoopitlatam" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li><a href="https://www.facebook.com/qoopit" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li><a href="https://linkedin.com/company/qoopit-app" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

DashboardModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
};

export default DashboardModule;
